@import "src/styles/variables";

.color-value {
  max-width: 343px;
  width: 100%;
  display: flex;
  align-items: center;

  &__box {
    width: 16px;
    height: 16px;
    background: $primary;
    border: 1px solid $bg-border;
    border-radius: 4px;
  }

  &__color {
    margin-left: 4px;
    color: $content-low !important;
  }
}