@import 'styles/variables';

.saving-status {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    font-size: 18px;
    &__spinner {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        &::before {
            content: '';
            color: $white;
            height: 12px;
            width: 12px;
            background: transparent;
            border-radius: 50%;
            border: 2px solid transparent;
            border-color: $primary $primary transparent transparent;
            animation: load 0.6s infinite;
        }
    }
}

@media screen and (max-width: 768px){
    .saving-status {
        display: none;
    }
}