.confirm-desc {
  margin-top: 24px;
  max-width: 538px;
  width: 100%;
  text-align: center;
  line-height: 150%;

  &__email {
    font-weight: 700;
  }

  &--secondary {
    margin-top: 10px;
  }
}