@import "styles/variables";

.manage-group-students-modal {
  & > div {
    max-width: 634px;
    height: 660px;
    width: 100%;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    position: absolute;
    bottom: 0;
    height: 64px;
    width: 100%;
    margin: 0 -48px;
    border-radius: 0 0 16px 16px;
    box-shadow: 0px -0.3px 0px #ECEBF5, 0px 0px 16px rgba(0, 0, 0, 0.04);
    opacity: 0.9;

    & > button {
      margin-top: 0;
      width: calc(100% - 96px);
    }
  }

  &__input {
    max-width: 100%;
    margin-top: 36px;
  }

  &__list {
    margin-top: 16px;
    overflow-y: auto;
    max-height: 430px;
    padding-right: 8px;
    margin-bottom: 35px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 16px;

    & > * {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:hover {
      background-color: $bg-secondary;
    }

    .item {
      &__close {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
}