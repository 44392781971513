.section-test__create {
    display: flex;
    flex-direction: column;
    align-items: center;
    .smooth-dnd-container {
        min-height: auto;
    }
    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
        overflow: visible;
    }
    .test-name {
        width: 100%;
        max-width: 750px;
        &.test-name-mentor {
            font-size: 32px;
            font-weight: 700;
            border: 0;
            color: #061822;
            &::placeholder {
                font-size: 32px;
                font-weight: 700;
            }
            &:disabled {
                box-shadow: none;
            }
        }
    }
}
