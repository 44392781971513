.preview-layout {
    &__header {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.01);
    }
    &__main {
        max-width: 1315px;
        padding: 46px 16px 0;
        margin: 0 auto;
    }
}