@import "src/styles/variables";

.profile-menu-list {
  &__item {
    &:first-child {
      border-radius: 12px 12px 0 0;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    padding: 13.5px 16px;
    border-radius: inherit;

    &:hover {
      background-color: $bg-secondary;
      cursor: pointer;

      .nav {
        &__name {
          color: $content-major;
        }
      }
    }

    &.active {
      .nav {
        &__name {
          color: $primary;
        }

        &__icon > svg > path {
          fill: $primary;
        }
      }
    }

    &.border {
      border-bottom: 1px solid $bg-border;
    }

    .nav {
      &__name {
        margin-left: 12px;
        color: $content-low;
      }
    }
  }
}