@import "src/styles/variables";

.header {
  width: 100%;
  height: 56px;
  grid-area: header;
  background-color: $white;
  box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.01);
  padding: 0 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;

  &-search {
    max-width: 538px;

    @media screen and (max-width: 975px) {
      max-width: 438px;
    }
    @media screen and (max-width: 875px) {
      max-width: 338px;
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    &.base-header {
      position: fixed;
    }
    .arrow-back {
      left: 20px;
      position: absolute;
      cursor: pointer;
    }
    &-search {
      max-width: 100%;
    }
  }
}
