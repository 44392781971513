.spaces-list {
  width: 100%;
  margin-top: 48px;

  & > * {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__btn {
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
  }

  &__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }
}