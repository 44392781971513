@import "../../../../styles/variables";

.users-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__btn {
    & > .icon {
      margin-left: 8px;

      & > svg > path {
        stroke: $light-emotion-link;
      }
    }

    & > p {
      font-weight: 500;
      color: $light-emotion-link;
    }
  }

  &__more {
    display: flex;
    align-items: center;

    & > p {
      font-weight: 500;
      color: $content-link;
    }

    & > .icon {
      margin-left: 8px;
    }
  }

  &__name {
    color: $content-medium !important;
  }
}

@media screen and (max-width: 768px){
  .users-title {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 425px) {
  .users-title {
    &__name {
      font-size: 24px !important;
    }
  }
}