@import 'src/styles/variables';
.block-item {
    border: 1px solid #edeff0;
    border-radius: 12px;
    width: 750px;
    padding: 20px;
    position: relative;
    min-height: 225px;
    .radio, .checkbox {
        align-items: flex-start;
    }
    textarea {
        margin-top: 10px;
        resize: none;
        height: 100%;
        border: 0;
        &.block-item-description {
            font-size: 14px;
            color: $content-medium;
            font-weight: 400;
            line-height: 140%;
        }
        &:last-child {
            input {
                color: #445259;
                font-size: 15px;
            }
        }
    }

    &-input__wrapper {
        width: 100%;
        height: 100%;
        textarea {
            overflow-y: hidden;
            font-weight: 600;
        }
    }
    &__answer {
        margin-top: 0!important;
        width: 100%;
        input:disabled {
            box-shadow: none;
        }
    }
    &__action {
        margin-top: 30px;
    }
    .create-test-list {
        right: 25px;
        top: 40px;
    }
    &-top__wrapper {
        display: flex;
        margin-top: -15px;
        textarea {
            max-width: 100%;
            width: 100%;
            font-size: 17px;
            color: $content-major;
            &::placeholder {
                color: #a8afb2;
                font-size: 17px;
            }
        }
    }
    &__icons {
        display: flex;
        cursor: pointer;
        svg {
            &:last-child {
                transform: rotate(90deg);
            }
        }
    }
    &__fields {
        margin-top: 25px;
        &:last-child {
            margin-bottom: 25px;
        }
        &-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 22px;
            .d-flex {
                align-items: flex-start;
            }
            .block-item__fields-actions {
                opacity: 0;
                visibility: hidden;
                cursor: pointer;
            }
            &:hover {
                .block-item__fields-actions {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
.student-test {
    .block-item {
        min-height: auto;
    }
}
.block-wrapper {
    display: flex;

    margin-top: 30px;
    .test-move {
        cursor: pointer;
        opacity: 0;
    }
    &:hover {
        .test-move {
            opacity: 1;
        }
    }
    &__read-only:hover {
        .test-move {
            opacity: 0;
        }
    }
}

.isDuplicate {
    position: relative;
    &:after {
        content: 'Дубликат()';
        position: absolute;
        right: -10%;
    }
}

.block-item-field__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
    margin-left: 10px;
}

@media (max-width: 800px) {
    .block-wrapper,
    .student-test,
    .smooth-dnd-draggable-wrapper,
    .smooth-dnd-container {
        width: 100%;
        .block-item {
            width: 100%;
        }
    }
    .block-wrapper {
        .test-move {
            opacity: 1;
        }
    }
    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
        overflow-y: auto !important;
    }
}


textarea {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}