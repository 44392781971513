@import "styles/variables";
.statistics-item {
  &:first-child {
    margin-right: 50px;
  }
  .value {
    color: $content-medium;
    margin-top: 7px;
  }
  .title {
    color: $content-low;
    font-weight: 600;
    font-size: 11px;
  }
  .title-black {
    @extend .title;
    color: $content-major;
  }
  .value-black {
    @extend .value;
    color: $content-major
  }
}