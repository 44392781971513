@import "styles/variables";
.about-file-content {
  h5 {
    margin-bottom: 30px;
  }
  &__list {
    li {
      margin-top: 25px;
      div {
        font-size: 15px;
        color: #061822;
      }
      span {
        color: $content-low;
        display: block;
        margin-bottom: 12px;
      }
    }
  }
}



@media (max-width: 768px) {
    .about-file-content {
      padding: 20px;
      h5 {
        text-align: center;
        font-size: 20px;
      }
    }
}