@import "../../../../styles/variables";

.profile-main {
  max-width: 539px;
  padding: 16px 24px;
  border: 1px solid $bg-border;
  border-radius: 24px;

  &__fields {
    & > * {
      margin-top: 32px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__group {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    & > div > p {
      position: absolute;
    }
  }

  &__btn {
    margin-top: 48px;
  }
}

@media screen and (max-width: 768px){
  .profile-main {
    &__fields {
      margin-bottom: 54px;
    }

    &__group {
      flex-wrap: wrap;

      & > * {
        margin-bottom: 24px;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}