@import "../../../../styles/variables";

.user-empty {
  max-width: 538px;
  width: 100%;
  margin: 128px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__title {
    line-height: 140%;
    color: $content-medium;
  }

  &__desc {
    line-height: 150%;
    margin-top: 16px;
    text-align: center;
  }

  &__btn {
    margin-top: 24px;
  }
}

@media screen and (max-width: 768px){
  .user-empty {
    margin-top: 72px;
    padding-left: 16px;
    padding-right: 16px;
  }
}