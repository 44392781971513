@import "../../../../../styles/variables";

.student-courses-access {
  &__title {
    color: $content-medium;
  }

  &__list {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    row-gap: 20px;
  }

  &__item {
    padding: 16px;
    position: relative;
    display: block;
    width: 100%;
    &:hover {
      background: #fff;
      box-shadow: 0 4px 24px rgba(56, 166, 226, 0.08);
      border-radius: 16px;
    }
    .item {
      &__img {
        cursor: pointer;
        max-width: 100%;
        width: 100%;
        height: 193px;
        border-radius: 16px;
        object-fit: cover;
      }

      &__fav {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        cursor: pointer;

        &--lock {
          left: 24px;
        }

        & > div > svg {
          width: 24px;
          height: 24px;
        }

        &.isWhite {
          & > div > svg > path {
            fill: $white;
          }
        }
      }

      &__name {
        margin-top: 8px;
      }
    }
  }
}

@media screen and (max-width: 1440px){
  .student-courses-access {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 1024px){
  .student-courses-access {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 525px){
  .student-courses-access {
    &__list {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
    }
  }
}

@media screen and (max-width: 375px){
  .student-courses-access {
    &__title {
      font-size: 24px;
    }
  }
}