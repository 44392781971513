@import "styles/variables";

.course-item {
  padding: 8px;
  display: flex;
  align-items: center;

  &:hover, &.selected {
    background-color: $bg-secondary;
  }

  &__img {
    width: 85px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
  }

  &__name {
    font-weight: 600;
    max-width: 350px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 545px) {
      max-width: 250px;
    }

    @media screen and (max-width: 425px) {
      max-width: 200px;
    }

    @media screen and (max-width: 375px) {
      max-width: 150px;
    }
  }

  &__invite {
    color: $content-low;
  }

  &__checkbox {
    margin-left: auto;
  }
}