//colors
$white: #ffffff;
$white-smoke: #F5F5F5;
$primary: #38a6e2;
$primary-dark: #3270cc;
$black: #181818;
$gray: #b3b3b3;
$success: #21c93b;
$light-gray: #6c6c6c;
$trout: #494C4F;

$content-gray: #a8afb2;
$content-major: #061822;
$content-major-light: #16113D;
$content-medium: #445259;
$content-low: #637d8b;
$content-link: #2a7af2;
$content-disabled: #CDD1D3;

$emotion-bad: #ff2f24;

$bg-secondary: #f7f9fa;
$bg-border: #edeff0;
$primary-gray:  #A8AFB2;
$light-black: #445259;
$light-emotion-link: #2F7CF6;
$low-black: #637D8B;
$sidebar-box-shadow-large: rgba(0, 0, 0, 0.01);
$sidebar-box-shadow-small: rgba(0, 0, 0, 0.04);