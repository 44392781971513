@import 'variables';
@import "fonts";

*:focus {
    outline: none;
}

html {
    overflow-y: scroll;
}

body {
    margin: 0;
    font-family: Inter;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-size: 14px;
}

input[type="password"] {
    font-family: Inter sans-serif;
}

h1 {
    font-size: 80px;
    font-weight: 700;
    color: $black;
}
h2 {
    font-weight: 700;
    font-size: 56px;
}
h3 {
    font-weight: 700;
    font-size: 44px;
}

h4 {
    font-weight: 600;
    font-size: 32px;
}

h5 {
    font-weight: 600;
    font-size: 24px;
}

h6 {
    font-weight: 600;
    font-size: 20px;
}
.MuiFormControl-root {
    width: 100%;
}

a {
    text-decoration: none;
    color: $content-link;
}

.MuiInputLabel-standard {
    color: $gray !important;
    &.Mui-focused {
        color: $primary !important;
    }
}

.fw-500 {
    font-weight: 500;
}
.fw-mini {
    font-weight: 600;
}
.fw {
    font-weight: bold;
}
.MuiInputBase-colorSuccess {
    &:after {
        border-bottom: 2px solid $success !important;
    }
}

ul,
li {
    list-style: none;
}

.textarea-cls {
    width: 100%;
    border: 0;
    resize: none;
}

.only-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.text-center {
    text-align: center;
}

.spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    &-loader {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 20px;
        &::before {
            content: '';
            color: #fff;
            height: 50px;
            width: 50px;
            background: transparent;
            border-radius: 50%;
            border: 10px solid transparent;
            border-color: $primary $primary transparent transparent;
            animation: load 0.6s infinite;
        }
    }
}

@keyframes load {
    100% {
        transform: rotatez(360deg);
    }
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.content {
    margin-top: 40px;
}

.img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.d-flex {
    display: flex;
    align-items: center;
}

.j-c-b {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.icon--no-stroke div > svg > path {
    stroke: none !important;
}

.break-all {
    word-break: break-all;
}




@media (max-width: 768px) {
    .full-modal-mobile {
        padding: 0;
        .modal {
            padding: 0;
            display: block;
            &__close {
                display: none;
            }
            &__content {
                height: 100%;
                border-radius: 0;
                width: 100%;
                padding-top: 80px;
                padding-left: 20px;
                padding-right: 20px;
            }
            &__close {
                right: 24px;
                top: 13px;
                background: transparent;
            }
        }
    }
    .mobile-hidden {
        display: none;
    }
}

@media (min-width: 768px) {
    .desktop-hidden {
        display: none!important;
    }
}


.modal-title {
    font-size: 28px;
    font-weight: 700;
    color: $content-major;
    margin-bottom: 36px;
}