@import "src/styles/variables";

.settings-card {
  margin-top: 36px;

  &__title {
    color: $content-medium;
  }

  &__desc {
    margin-top: 9px;
    color: $content-low !important;
  }

  &__content {
    padding: 10px 0 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $bg-border;

    &.isError {
      border-bottom-color: $emotion-bad;
    }
  }

  &__children {
    height: 36px;
    display: flex;
    align-items: center;
  }

  &__buttons {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__errorText {
    padding-left: 15px;
    margin-top: 4px;
    color: $emotion-bad !important;
  }
}

@media screen and (max-width: 575px) {
  .settings-card {
    &__content {
      flex-wrap: wrap;

      .settings-card__buttons {
        margin-top: 16px;
      }
    }

    &__children {
      min-width: 193px;
    }
  }
}