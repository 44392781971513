.coursesDeleteModal {
  & > div {
    max-width: 408px;
    width: 100%;
  }

  &__title {
    text-align: center;
  }

  &__desc {
    margin-top: 24px;
    text-align: center;
    line-height: 150%;
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}