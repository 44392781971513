@import 'styles/variables';
.courses-detailed {
    &-img {
        width: 100%;
        height: 412px;
        margin-bottom: 32px;
        @media (max-width: 768px) {
            height: 192px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
        }
    }
    &-wrapper {
        display: flex;
        justify-content: space-between;
    }
    &-left {
        width: 70%;
        margin-right: 48px;
        @media (max-width: 768px) {
            width: 100%;
        }
        p {
            line-height: 150%;
            font-size: 15px;
        }
        h4 {
            margin-bottom: 24px;
            word-break: break-all;
        }
    }
    &-right {
        width: 30%;
        background: #ffffff;
        box-shadow: 0px 4px 24px rgba(56, 166, 226, 0.08);
        border-radius: 12px;
        height: 217px;
        padding: 24px 28px;
        h6 {
            margin-bottom: 24px;
        }
    }
    &-progress {
        margin-bottom: 25px;
        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 9px;
            span {
                color: #637d8b;
            }
        }
    }
    &-files {
        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        &__title {
            font-weight: 600;
            font-size: 17px;
            margin-top: 24px;
            margin-bottom: 19px;
        }
        &__item {
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: $primary-gray;
            }
        }
        &__name {
            margin: 0 10px;
        }
    }
    &-content {
        &__wrapper {
            .modal__content {
                padding: 0 32px;
                overflow-y: scroll;
            }
        }
        &__title {
            font-weight: 600;
            font-size: 21px;
            color: $content-major;
            margin-top: 32px;
            margin-bottom: 32px;
        }
        &-icon {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            a {
                display: block;
                width: 100%;
            }
            &-title {
                margin-left: 20px;
                color: $light-black;
                font-size: 15px;
                word-break: break-word;
                font-weight: 600;
            }
        }
        &-toggle {
            margin-bottom: 8px;
            &__items {
                margin-left: 30px;
            }
            &__title {
                cursor: pointer;
                margin-bottom: 20px;
                > div {
                    font-weight: 600;
                    font-size: 17px;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                .icon {
                    justify-content: flex-end;
                    align-items: flex-end;
                    svg {
                        transform: translateX(22%);
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        &-content {
            padding: 0 16px;
            &__title {
                text-align: center;
                font-size: 17px;
            }
        }
        &-left {
            margin-right: 0;
            h4 {
                font-size: 24px;
            }
            .progress {
                max-width: 100%;
            }
        }
    }
}

.courses-detailed-header-mobile {
    width: 100%;
    &__title {
        margin-left: 20px;
        font-weight: 600;
        font-size: 17px;
        color: $content-major;
    }
}
