@import 'src/styles/variables';

.courses-list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 10px;
    overflow: visible !important;

    &.shift {
        padding-bottom: 76px;
    }

    & > * {
        padding: 16px;
        margin-right: 32px;
        margin-bottom: 32px;
        position: relative;
        max-width: 343px;
        width: 100%;
        &:hover {
          background: #fff;
          box-shadow: 0 4px 24px rgba(56, 166, 226, 0.08);
          border-radius: 16px;
        }
    }

    &__last {
        height: 193px;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $bg-secondary;
        cursor: pointer;
        border-radius: 16px;

        & > p {
            margin-top: 16px;
            font-weight: 600;
            line-height: 120%;
            color: $content-low;
        }
    }
}

@media screen and (max-width: 1024px) {
    .courses-list {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media screen and (max-width: 475px) {
    .courses-list {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;

        & > * {
            margin-right: 0;
        }
    }
}
