.profile-notification {
  &__title {
    margin-bottom: 48px;
  }
}

.profile-spoiler {
  &__content {
    margin-left: 32px;

    & > * {
      margin-bottom: 12.5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: 400;
    line-height: 140%;
  }
}