.content-layout {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 219px 1fr 1fr 1fr;
  grid-template-rows: 56px 1fr;
  grid-template-areas:
          "header header header header header"
          "sidebar main main main main";

  & > .layout-main {
    grid-area: main;
    max-width: 1221px;
    width: 100%;
    padding: 36px 48px 36px 38px;
  }
}

@media screen and (max-width: 768px){
  .content-layout {
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr 56px;
    grid-template-areas:
          "header header header header header"
          "main main main main main"
          "sidebar sidebar sidebar sidebar sidebar";

    & > .layout-main {
      padding: 24px 16px;
    }
  }
}