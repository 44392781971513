@import "src/styles/variables";

.auth {
  position: relative;

  &-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 343px;
    margin: 0 auto;

    &--large {
      max-width: 538px;
    }
  }

  &-title {
    width: 100%;
    text-align: center;
    color: $content-medium !important;
  }

  &-form {
    width: 100%;
    margin-top: 48px;

    & > .input {
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &-desc {
    width: 100%;
    margin-top: 12px;
    text-align: center;
    color: $content-low;
  }
}


@media screen and (max-width: 375px){
  .auth {
    &-title, &-desc {
      text-align: left;
    }

    &-form {
      width: 100%;
      margin-top: 36px;
    }
  }
}

@media screen and (max-width: 343px) {
  .auth {
    padding: 0 16px;
  }
}