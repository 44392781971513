@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');


button, select, ul, li, textarea {
  font-family: 'Inter', sans-serif;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background-color: inherit;
}

*::-webkit-scrollbar-thumb {
  background-color: $content-disabled;
  border-radius: 4px;
}

.w-100 {
  width: 100%;
}

