@import "src/styles/variables";

.settings-company-fav-icon {
  &__tag {
    background: $bg-secondary;
    border-radius: 8px 8px 0 0;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 193px;

    & > span {
      display: flex;
      align-items: center;

      & > img {
        width: 16px;
        height: 16px;
        margin-right: 9px;
      }

      & > p {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.2px;
        color: $trout;
      }
    }
  }

  &__upload {
    display: none;
  }
}