@import "styles/variables";

.selectable-modal {
  & > div {
    max-width: 634px;
    height: 660px;
    width: 100%;
  }

  &__search {
    margin-top: 24px;
  }

  &__filter {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__count {
    color: $content-low;

    & > span {
      color: $content-medium;
    }
  }

  &__all {
    cursor: pointer;
    padding: 7px 12px;
    border: 1px solid $bg-border;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .icon {
      margin-left: 5.5px;
      width: 19px;
      height: 19px;

      & > svg > path {
        fill: $content-low;
      }
    }

    &:hover {
      border-color: $content-major;

      & > p {
        color: $content-major;
      }

      & > .icon {
        & > svg > path {
          fill: $content-major;
        }
      }
    }

    &.isSelectedAll {
      border-color: $content-major;
      background-color: $content-major;

      & > p {
        color: $white;
      }

      & > .icon {
        & > svg > path {
          fill: $white;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  &__list {
    margin-top: 16px;
    overflow-y: auto;
    max-height: 406px;
    padding-right: 8px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    position: absolute;
    bottom: 0;
    height: 64px;
    width: 100%;
    margin: 0 -48px;
    border-radius: 0 0 16px 16px;
    box-shadow: 0px -0.3px 0px #ECEBF5, 0px 0px 16px rgba(0, 0, 0, 0.04);
    opacity: 0.9;

    & > button {
      width: calc(100% - 96px);
    }

    &.multiButton {
      & > *:first-child {
        margin-right: 24px;
        margin-left: 48px;
      }

      & > *:last-child {
        margin-right: 48px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .selectable-modal {
    &__search {
      width: calc(100% - 24px) !important;
      margin-left: 12px;
    }

    &__filter, &__list {
      padding: 0 12px;
    }

    &__list {
      max-height: 640px !important;
      height: 100% !important;
    }

    &__btn {
      margin: 0;
      opacity: 1;

      & > button {
        width: calc(100% - 24px);
      }
    }
  }
}