@import 'src/styles/variables';

.course-module > .dropdown {
    position: static;
}

.material-options {
    &__title {
        padding: 12px 0;
        text-align: center;
    }
    &__body {
        min-width: 280px;
    }
    &__name {
        font-weight: 500;
        padding-left: 12px;
    }
    &__icon {
        width: 40px;
        height: 40px;
        border: 1px solid #edeff0;
        border-radius: 12px;
    }
    &__item {
        cursor: pointer;
        height: 48px;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        &:first-child {
            height: 52px;
            padding-top: 8px;
            border-radius: 12px 12px 0 0;
        }
        &:last-child {
            height: 52px;
            padding-bottom: 8px;
            border-radius: 0 0 12px 12px;
        }
        &:hover,
        &:active,
        &:focus {
            background: $bg-secondary;
        }
    }
}
