.create-course-cover-bottom-sheet {
  padding: 16px 16px 0 16px;

  &__heading {
    position: relative;

    & > div {
      position: absolute;
      top: -2px;
      right: 0;
    }
  }

  &__title {
    text-align: center;
  }

  &__cropper {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
}