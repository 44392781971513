.create-course-cover-modal {
  &__cropper {
    margin-top: 24px;
  }

  &__ranges {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__range {
    width: 50%;
    margin-right: 64px;

    &:last-child {
      margin-right: 0;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & > input {
      margin-top: 8px;
      width: 100%;
    }
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}