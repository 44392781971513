@import "styles/variables";

.users-modal-wrapper {
  & > div {
    position: relative;
  }

  &.fixed > div {
    padding-bottom: 64px;
  }

  &.isSuccess > div > h2 {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
  }
}

@media screen and (max-width: 768px){
  .users-modal-wrapper {
    justify-content: start !important;
    align-items: start !important;
    padding: 0 !important;

    &.isSuccess > div > h2 {
      max-width: 285px;
    }

    & > .modal__content {
      max-width: 100%;
      width: 100%;
      height: 90%;
      border-radius: 0;
      padding: 0;

      & > .modal__close {
        top: -2px;
        right: 0;
        background-color: inherit;

        & > .icon > svg {
          width: 36px;
          height: 36px;
        }
      }
    }

    &__title {
      padding: 12px 0;
      text-align: center;
      font-size: 17px !important;
      font-weight: 600 !important;
    }
  }
}