.course-navigation {
    align-items: center;
    padding: 15px 15px 10px;
    background: #fff;
    svg path {
        fill: #2a7af2;
    }
    &__link {
        display: flex;
        align-items: center;
        color: #2a7af2;
        cursor: pointer;
        &--next {
            padding-left: 32px;
        }
        &--disabled {
            pointer-events: none;
            color: #cdd1d3;
            svg path {
                fill: #cdd1d3;
            }
        }
    }
    &__text {
        padding-left: 10px;
    }
    @media screen and (max-width: 600px) {
        &__course {
            display: none;
        }
        &__materials {
            justify-content: space-between !important;
        }
    }
}
