@import "../../../../styles/variables";

.auth-desc {
  width: 100%;
  margin-top: 12px;
  text-align: center;
  color: $content-low;
}

@media screen and (max-width: 375px){
  .auth-desc {
    text-align: left;
  }
}