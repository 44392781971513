@import "../../../../styles/variables";

.student-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  & > .icon {
    cursor: pointer;
  }

  &:hover, &.selected {
    background-color: $bg-secondary;

    .student-item__name {
      color: $content-major;
      font-weight: 500;
    }
  }

  &.invited {
    .student-item__name {
      color: $content-gray;
    }
  }

  &__name {
    &:hover, &:active, &:focus {
      color: $content-major;
    }
  }

  &__email {
    font-weight: 600 !important;
    color: $content-low !important;
  }

  &__avatar {
    cursor: pointer;
  }

  &__circle {
    cursor: pointer;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 1px dashed $bg-border;
    border-radius: 36px;
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: center;

    & > * {
      &:first-child {
        width: 60%;
        margin-right: 16px;
      }

      &:last-child {
        max-width: 228px;
        width: 40%;
      }
    }

    & > div > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & > p {
        margin-right: 4px;
        word-break: break-word;
      }
    }
  }

  &__invited {
    background: $bg-secondary;
    border-radius: 4px;
    padding: 0 4px;

    & > p {
      color: $content-low
    }
  }

  &__qty {
    max-width: 106px;
    width: 100%;

    & > p:first-child {
      font-weight: 600 !important;
      color: $content-low;
    }
    & > p:last-child {
      font-weight: 500 !important;
    }
  }

  &__more {
    cursor: pointer;
  }

  &__dropdown {
    margin-left: auto;
    cursor: pointer;

    .dropdownBody {
      right: 0;
      padding: 8px 0;
      width: 219px;
    }
  }

  &__action {
    padding: 9px 16px;

    &.border {
      border-bottom: 1px solid $bg-border;
    }

    &:hover, &:active, &:focus {
      background-color: $bg-secondary;
      cursor: pointer;

      & > p {
        color: $content-major;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .student-item {
    & > .checkbox, &__qty, &__dropdown {
      display: none;
    }

    &__arrow {
      display: block;
    }

    &__content {
      & > * {
        &:first-child {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}