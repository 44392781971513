@import "src/styles/variables";

.menu-item {
  padding: 13.5px 16px;
  display: flex;
  align-items: center;

  &.isDivider {
    border-bottom: 1px solid $bg-border;
  }

  &:hover, &:focus {
    background-color: $bg-secondary;

    & > a > p {
      color: $content-major;
    }
  }

  &.active {
    & > p {
      color: $primary !important;
    }

    & > div > svg > path {
      fill: $primary;
    }
  }

  &__name {
    margin-left: 12px;
    color: $content-low;
  }
}