@import 'src/styles/variables';

.coursesDropdown {
    .end {
        top: 0;
        transform: translateY(-100%);
    }

    .start {
        top: 0;
        transform: translateY(0);
    }

    &__body {
        width: 290px;
        top: 50%;
        transform: translateY(-50%);
        right: 44px;
        z-index: 99 !important;
    }

    &__list {
        & > * {
            &:first-child {
                height: 52px;
                padding-top: 8px;
                border-radius: 12px 12px 0 0;
            }

            &:last-child {
                height: 52px;
                padding-bottom: 8px;
                border-radius: 0 0 12px 12px;
            }
        }
    }

    &__item {
        cursor: pointer;
        height: 48px;
        padding: 4px 16px;
        display: flex;
        align-items: center;

        &.divider {
            border-bottom: 2px solid $bg-border;
        }

        &:hover,
        &:active,
        &:focus {
            background: $bg-secondary;
        }
    }

    &__name {
        font-weight: 500;
        margin-left: 12px;
    }

    &__icon {
        width: 40px;
        height: 40px;
        border: 1px solid $bg-border;
        border-radius: 12px;

        & > svg {
            width: 24px;
            height: 24px;
        }
    }

    &__title {
        padding: 12px 0;
        text-align: center;
    }
}
