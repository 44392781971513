@import "styles/variables";
.analytics-header-mobile {
  display: none;
}
@media (max-width: 768px) {
  .analytics-header-mobile {
    height: 60px;
    position: fixed;
    background: rgba(255, 255, 255, 0.85);
    /* Navbar */
    left: 0;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0.3px 0px #ECEBF5, 0px 0px 16px rgba(0, 0, 0, 0.04);
    width: 100%;
    top: 0;
    display: flex;
    &__title {
      font-size: 17px;
      overflow:hidden;
      text-overflow: ellipsis;
      margin: 0 15px;
      color: $content-major;
      font-weight: 600;
    }
  }
}