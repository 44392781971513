@import "../../../../styles/variables";

.bottom-modal {
  max-width: 1125px;
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 18px 48px;
  transform: translateX(-50%);
  background: $white;
  box-shadow: 0 0.3px 0 #ECEBF5, 0 0 16px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border-radius: 16px 16px 0 0;
  z-index: 5;
}