.test-add {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
  span {
    color: #A8AFB2;
    display: block;
    margin-left: 8px;
  }
}