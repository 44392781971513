@import "styles/variables";
.analytics {
  &-emoji {
    margin: 50px 0;
    &__item {
      margin-left: 88px;
      &:first-child {
        margin-left: 0;
      }
      &-img {
        margin-right: 5px;
      }
      &-img, &-cnt  {
        font-size: 28px;
      }
      &-title {
        font-size: 15px;
        margin-top: 5px;
        color: $content-medium;
      }
    }
  }
}

@media (max-width: 768px) {
  .analytics {
    &-emoji {
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
       width: 0;
        height: 0;
      }
      &__item {
        margin-left: 26px;
        &-title {
          font-size: 13px;
          white-space: nowrap;
          margin-top: 10px;
        }
      }
    }
  }
}