@import "../../../../styles/variables";

.space-card {
  padding: 12px 16px;
  display: flex;
  align-items: center;

  &:hover, &:focus, &:active {
    background: $white;
    box-shadow: 0 4px 24px rgba(56, 166, 226, 0.08);
    border-radius: 12px;
    cursor: pointer;
  }

  &__img {
    max-width: 48px;
    max-height: 48px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-right: 16px;
    &.isSquare {
      border-radius: 0;
    }
  }

  &__content {
    display: inherit;
    flex-direction: column;

    & > p {
      margin-top: 4px;
    }
  }
}