@import 'styles/variables';

.course {
    opacity: 0;
    margin: 0 auto;
    .codex-editor__redactor {
        padding: 0 0 24px !important;
    }
    &--ready {
        opacity: 1;
    }
    &__title {
        &-wrapper {
            max-width: 734px;
            margin: 0 auto;
        }
        padding-left: 0;
        padding-right: 0;
        resize: none;
        width: 100%;
        font-size: 32px;
        margin: 32px 0 24px;
        border: none;
        font-weight: 700;
        &::placeholder {
            color: $content-gray;
        }
        &:disabled {
            color: #000;
            background: transparent;
        }
    }
    .ce-popover__item[data-item-name="code"]{
        display: none;
        background: green;
    }
}
