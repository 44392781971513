@import "../../../../styles/variables";

.confirm {
  &-title {
    width: 100%;
    color: $content-medium;
    text-align: center;
  }

  &-btn {
    margin-top: 36px;
    max-width: 340px;
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .confirm {
    &-title, &-desc {
      text-align: left;
    }
  }
}