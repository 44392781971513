.course-content-button {
    padding: 14px;
    z-index: 99;
    top: 40%;
    &__text {
        padding-right: 10px;
    }
    @media (max-width: 800px) {
        display: flex !important;
        &__text {
            display: none;
        }
    }
}
