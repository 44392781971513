@import "styles/variables";
.analytics-detail-course {
  .users-table-filter {
    margin-bottom: 43px;
  }
  .users-heading__tab {
    .icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__profile {
    &-date {
      font-size: 11px;
      font-weight: 600;
      div {
        margin-left: 5px;
        color: $content-medium;
      }
      span {
        color: $content-low;
      }
    }
    &-title {
      font-size: 17px;
      color: $content-medium;
      font-weight: 600;
      margin-bottom: 10px;
    }
    &-img {
      width: 96px !important;
      height: 96px !important;
      min-height: 96px !important;
      min-width: 96px !important;
      margin-right: 24px;
      font-size: 32px;
      &.circle {
        img {
          border-radius: 72px;
        }
      }
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-statistics {
      margin-left: 46px;
    }
    @media (max-width: 768px) {
      &-statistics {
        margin-left: 0;
        margin-top: 20px;
      }
      &-wrap {
        flex-direction: column;

      }
    }
    &.student-detail-profile {
      .analytics-detail-course__profile-date {
        margin-bottom: 10px;
      }
    }
  }
}


.analytics-test-statistics {
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  &__itemWrap {
    margin-bottom: 56px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    color: $content-medium;
    font-size: 21px;
    font-weight: 700;
  }
  &__total {
    font-size: 10px;
    font-weight: 600;
    color: $content-low;
    margin-bottom: 36px;
    margin-top: 5px;
  }
  &__mainTitle {
    color: $content-medium;
    margin-bottom: 16px;
    margin-top: 50px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__item {
    display: flex;
    margin-bottom: 20px;
    &.success {
      .progress {
        &__bg {
          background: #65DE78;
        }
      }
    }
    &-title {
      color: $content-low;
      margin-right: 20px;
      width: 180px;
      div {
        font-size: 13px;
        white-space: nowrap;
      }
    }
    &-percent {
      width: 50px;
    }
    &-progress {
      width: 100%;
      .progress {
        max-width: 100%;
        margin-right: 8px;
        width: 120px;
        &__bg {
          background: #FF7070;
        }
      }
    }
  }
  .TestStatisticsList__list-title {
    font-size: 13px;
    margin-bottom: 16px;
    color: $content-medium
  }
}