@import "src/styles/variables";

.settings-item {
  margin-top: 36px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__desc {
    margin-top: 9px;
    color: $content-low !important;
  }

  &__divider {
    margin-top: 20px;
  }

  &__content {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: 24px;

    & > *:first-child {
      margin-right: 12px;
    }

    & > *:last-child {
      max-width: 121px;
    }
  }

  &__select {
    max-width: 343px;
  }
}

@media screen and (max-width: 575px) {
  .settings-item {
    &__content {
      flex-wrap: wrap;
    }

    &__buttons {
      margin-top: 16px;
      margin-left: auto;
      margin-right: auto;
    }

    &__color, &__select {
      max-width: 575px;
    }
  }
}
