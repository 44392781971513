@import 'styles/variables';
.student-test {
    .test-name {
        margin-bottom: 30px;
    }
    textarea {
        &:disabled {
            background: none;
        }
    }
    &__btn {
        max-width: 750px;
        margin: 0 auto;
    }
    &__material-navigation {
        max-width: 784px;
        margin: 0 auto;
        padding: 0 15px 35px;
        position: sticky;
        top: 50px;
        z-index: 999;
        @media (max-width: 768px) {
            position: fixed;
            top: unset;
            left: 0;
            bottom: 56px;
            width: 100%;
            max-width: 100%;
        }
    }
    .input__field:disabled,
    .input__field:read-only {
        box-shadow: none;
    }
    .radio,
    .checkbox {
        &.error {
            .radio__field:checked,
            .checkbox__field:checked {
                background-color: $emotion-bad;
                border-color: $emotion-bad;
            }
        }
        &.success {
            .radio__field:checked,
            .checkbox__field:checked {
                background-color: $success;
                border-color: $success;
            }
        }
    }
    .block-item {
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .result-block-test {
        &__line {
            width: 100%;
            height: 2px;
            margin: 20px 0;
            &.success {
                background-color: $success;
            }
            &.error {
                background-color: $emotion-bad;
            }
        }
    }
}

.modal-item {
    &__title {
        font-size: 28px;
        font-weight: 700;
        color: $content-major;
        margin-bottom: 36px;
    }
    &__desc {
        color: $content-medium;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
    }
}

.test-link {
    color: #2a7af2;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    &.bold {
        cursor: pointer;
        font-weight: bold;
    }
}

@media (max-width: 768px) {
    .test-modal {
        .MuiBox-root {
            width: 90% !important;
        }
    }
}
