.settings-company-logo {
  &__wrapper {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 9px;
      width: 24px;
      height: 24px;
    }
  }

  &__upload {
    display: none;
  }
}