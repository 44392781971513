@import "styles/variables";
.sort-list {
  display: flex;
  align-items: center;
  div {
   color: $content-medium;
    white-space: nowrap;
    margin-right: 12px;
  }
  ul {
    display: flex;
    align-items: center;
    overflow-x: auto;
    li {
      display: flex;
      align-items: center;
      border: 1px solid #EDEFF0;
      border-radius: 12px;
      padding: 8px 12px;
      margin-right: 10px;
    }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .icon {
      margin-left: 5px;
    }
    span {
      color: $content-medium;
      font-weight: normal;
      white-space: nowrap;
    }
  }
}