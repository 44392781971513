@import "styles/variables";
.timer {
  &-result {
    color: $success;
    font-weight: 500;
  }
 >div {
   margin-right: 10px;
 }
  &-title-desktop {
    color: $low-black;
  }

  &-countdown {
    width: 77px;
    height: 28px;
    justify-content: center;
    background: rgba(42, 122, 242, 0.02);
    border-radius: 4px;
    span {

      color: $success;
    }
  }
  &-title {
    font-size: 17px;
    font-weight: 600;
    color: #16113D;
  }
}