.settings-company-app-name {
  .settings-card__children {
    width: 100%;
  }

  &__input {
    max-width: 100% !important;
    width: 100%;

    input {
      border-bottom: none;
      padding-left: 0;
    }
  }
}