@import 'styles/variables';

.publish-modal {
    .modal__content {
        max-width: 80vw;
    }
}

.publish-items {
    padding: 30px 0;
}

.publish-buttons {
    display: flex;
    justify-content: space-between;
    &__button {
        width: calc(50% - 12px);
    }
}

.publish-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 8px;
    margin-right: 8px;

    &:hover {
        background: $bg-secondary;
    }
    &--all {
        border-bottom: 1px solid $bg-border;
        padding-bottom: 21px;
        margin-right: 0;
    }
    &__controls {
        padding: 10px 0;
        width: calc(100% - 20px);
        display: flex;
        align-items: center;
        &--main {
            width: 50%;
        }
    }
    &__name {
        margin-left: 12px;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        &--all {
            font-weight: 500;
            color: $content-link;
        }
    }
}

.publish-list {
    max-height: 406px;
    overflow-y: auto;
}
