@import "styles/variables";
.customSelect {
  margin-left: 20px;
  position: relative;
  &__view {
    cursor: pointer;
    position: relative;
    justify-content: center;
    border: 1px solid #EDEFF0;
    border-radius: 12px;
    text-align: center;
    height: 32px;
    padding: 0 12px;
    &.isOpen {
      .icon {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__list {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(56, 166, 226, 0.08);
    border-radius: 12px;
    width: 170px;
    padding: 20px;
    z-index: 999;
    li {
      color: $light-black;
      font-size: 14px;
      margin-top: 18px;
      cursor: pointer;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &__value {
    width: 100%;
    font-size: 13px;
    color: $light-black;
    margin-right: 8px;
  }
}