.headerOffline {
  margin-left: 8px;
  color: #637D8B;

  & > .icon {
    margin-left: 4px;

    & > svg > path {
      fill: #637D8B;
      stroke: #637D8B;
    }
  }
}