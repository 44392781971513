@import "../../styles/variables";

.spaces {
  max-width: 438px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 144px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 475px) {
    padding: 0 16px;
  }

  &-title {
    @media screen and (max-width: 375px) {
      text-align: left;
    }
  }
}