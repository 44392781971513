@import "src/styles/variables";

.create-course-settings {
  display: grid;
  grid-template-columns: 245px auto;
  grid-gap: 48px;

  &__content {
    max-width: 636px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 56px;
    height: calc(100% - 56px);
    z-index: 150;
    background-color: $white;

    &__content {
      display: flex;
      justify-content: center;
      max-width: 100%;

      & > form {
        & > button {
          margin-top: 0;
          position: fixed;
          left: 50%;
          bottom: 16px;
          max-width: 539px;
          width: calc(100% - 24px);
          transform: translateX(-50%);
        }

        & > h2 {
          font-size: 21px !important;
          margin-bottom: 36px;
        }
      }
    }
  }
}