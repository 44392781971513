@import "src/styles/variables";

.active-link {
  padding: 15px 28px;
  display: flex;
  align-items: center;

  &.active {
    & > div > svg > path {
      fill: $primary;
    }

    & > p {
      color: $primary;
    }
  }

  &__text {
    margin-left: 12px;
  }
}