@import 'styles/variables';

.educational-files {
    &__title {
        font-weight: 600;
        font-size: 17px;
        margin-top: 24px;
        margin-bottom: 19px;
    }
    &__remove {
        margin-left: 10px;
        margin-bottom: 2px;
        cursor: pointer;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            margin-top: 18px;
        }
        span {
            color: $primary-gray;
        }
    }
    &__progress {
        max-width: 100%;
    }
    &__size {
        padding-right: 10px;
    }
    &__name {
        margin: 0 10px;
        cursor: pointer;
        color: #000;
    }
    &__percent {
        margin-right: 8px;
    }
}
