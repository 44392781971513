@import 'styles/variables';

.course-material {
    padding: 6px 0 6px 30px;
    position: relative;

    &--drag {
        opacity: 0.3;
    }

    &__title {
        margin-left: 16px;
        max-width: 450px;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;

        &.empty {
            color: $content-low !important;
        }

        @media screen and (max-width: 768px){
            max-width: 350px;
        }
        @media screen and (max-width: 425px){
            max-width: 275px;
        }
    }
    .input {
        max-width: 100%;
    }
    &__textarea {
        font-size: 15px;
        margin-left: 16px;
        font-weight: 600;
        border: none;
        width: calc(100% - 55px);
        max-width: calc(100% - 55px);
        padding: 10px 0;
        border-bottom: 1px solid #061822;
        resize: none;
    }
    &__check path {
        stroke: $success !important;
    }
    &__decline path {
        stroke: #eb5757 !important;
    }
    &__info {
        cursor: pointer;
    }
    &:hover .course-material__move,
    &:active .course-material__move {
        display: block;
    }
    &__read-only:hover .course-material__move,
    &__read-only:active .course-material__move{
        display: none;
    }
    &__move {
        position: absolute;
        left: -15px;
        top: 0;
        display: none;
    }
    &__chip {
        background-color: $bg-secondary !important;
        & > span {
            color: $content-low;

            @media screen and (max-width: 475px){
                font-size: 10px;
            }
        }
    }
}