@import "../../../styles/variables";

.customSwiper {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      color: $content-medium;

      @media screen and (max-width: 375px){
        font-size: 24px;
      }
    }

    &__helpers {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__buttons {
      margin-left: 12px;
      display: flex;
      order: 2;

      & > * {
        border: 0;

        &:first-child {
          border-radius: 8px 0 0 8px;
        }

        &:last-child {
          border-radius: 0 8px 8px 0;
        }
      }

      @media screen and (max-width: 375px){
        display: none;
      }
    }
  }

  &-swiper {
    margin-top: 24px;
  }
}

