@import "src/styles/variables";

.found-courses-empty {
  max-width: 538px;
  width: 100%;
  margin: 128px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    line-height: 140%;
    color: $content-medium;
  }

  &__desc {
    margin-top: 16px;
    line-height: 150%;
  }
}