@import "styles/variables";
.analytics {
  &-courses {
    .users-table-filter {
      margin-bottom: 43px;
      &__all {
        min-width: 60px;
        p {
          margin-left: 0;
        }
      }
    }
    &__title {
      font-size: 21px;
      color: $content-medium;
      font-weight: bold;
      margin-bottom: 32px;
    }
    &__progress {
      margin-top: 20px;
      &-title {
        color: $content-low;
        font-size: 13px;
        margin-bottom: 20px;
      }
      &-item {
        margin-bottom: 20px;
        .j-c-b {
          margin-bottom: 10px;
        }
        span {
          color: $content-low;
        }
        div {
          color: $content-major;
        }
      }
    }
    &__detail {
      @media (max-width: 768px) {
        padding: 0 20px;
      }
      .title {
        font-size: 13px;
      }
      .statistics-item {
        margin-bottom: 20px;
      }
      .value {
        font-size: 15px;
        color: $content-major;
        margin-top: 10px;
      }
    }
  }
}