.header-mentor-course {
    &__left,
    &__right,
    &__helper {
        display: flex;
        align-items: center;
    }

    &__helper {
        & > * {
            margin-right: 16px;

            @media screen and (max-width: 768px) {
                margin-right: 0;
            }
        }
    }

    &__more {
        display: none !important;
        margin-right: 6px;
    }

    @media screen and (max-width: 768px) {
        justify-content: space-between;
        &__more {
            display: block !important;
        }
    }
}
