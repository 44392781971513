@import "src/styles/variables";

.settings-colors-main-background-sample {
  margin-top: 16px;
  height: 56px;
  padding: 8px 48px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.01);

  &__left {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 24px;
    height: 24px;
    margin-left: 20px;
  }

  &__title {
    margin-left: 8px;
    color: $content-major;
    font-weight: 500;
  }

  &__arrow {
    margin-left: 8px;
  }

  &__right {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 16px;

      &:nth-child(4) {
        margin-right: 8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__icon, &__text {
    display: none !important;
  }
}

@media screen and (max-width: 768px){
  .settings-colors-main-background-sample {
    padding-right: 16px;

    &__icon, &__text {
      display: block !important;
      pointer-events: none;
    }

    &__left, &__right {
      display: none;
    }
  }
}