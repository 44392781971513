@import "src/styles/variables";

.student-course-detail-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;

  &:hover, &.selected {
    background-color: $bg-secondary;

    .student-course-detail-item__name {
      color: $content-major;
      font-weight: 500;
    }
  }

  &__img {
    margin-left: 16px;
    width: 85px;
    min-width: 85px;
    height: 48px;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
  }

  &__name {
    line-height: 140%;
    color: $content-medium;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info {
    margin-top: 2px;
    color: $content-low;
  }

  &__content {
    margin-left: 16px;
  }

  &__dropdown {
    margin-left: auto;
    cursor: pointer;

    .dropdownBody {
      right: 0;
      padding: 8px 0;
      width: 219px;
    }
  }

  &__action {
    padding: 9px 16px;

    &.border {
      border-bottom: 1px solid $bg-border;
    }

    &:hover, &:active, &:focus {
      background-color: $bg-secondary;
      cursor: pointer;

      & > p {
        color: $content-major;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .student-course-detail-item {
    margin: 0 -16px;

    &__dropdown, &__checkbox {
      display: none !important;
    }

    &__img {
      margin-left: 0;
    }
  }
}