@import 'src/styles/variables';
.MuiTooltip-tooltip {
    border-radius: 12px;
    padding: 15px !important;
    .MuiTooltip-popper {
        background-color: #fff;
    }
    li {
        margin-bottom: 15px;
        font-size: 13px;
        color: #ffffff;
        cursor: pointer;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.modal {
    z-index: 1000 !important;
}

.knowledge-base {
    height: 100%;
    max-width: 100%;
    position: relative;
    &-placeholders {
        color: $content-medium;
        font-size: 15px;
        text-align: center;
        max-width: 450px;
        margin: 0 auto;
        line-height: 22px;
        .btn {
            margin-top: 24px;
        }
    }
    .modal {
        &__content {
            width: 100%;
            max-width: 637px;
        }
    }
    &-loading {
        font-size: 17px;
        text-align: center;
        margin-top: 50px;
    }
    &-placeholder {
        max-width: 500px;
        margin: 120px auto 0 auto;
        text-align: center;
        &__title {
            font-weight: 600;
            font-size: 19px;
            margin-bottom: 25px;
        }
        &__desc {
            font-weight: 400;
            font-size: 15px;
            margin-bottom: 25px;
            line-height: 25px;
            color: $content-medium;
        }
        .knowledge-base__actions-item {
            display: inline-flex;
            height: 36px;
            span {
                font-weight: 500;
                color: $content-medium;
            }
        }
    }
    .MuiCheckbox-root {
        color: #edeff0;
        &.Mui-checked {
            color: $primary;
        }
    }
    &__footer {
        background: #ffffff;
        width: calc(100% - 219px);
        padding: 0 50px;
        left: 219px;
        height: 80px;
        box-shadow: 0px 0.3px 0px #ecebf5, 0px 0px 16px rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(16px);
        border-radius: 16px 16px 0px 0px;
        position: fixed;
        bottom: 0;
        &-remove {
            cursor: pointer;
            div {
                color: #eb5757;
                font-weight: 500;
                font-size: 14px;
            }
            svg {
                path {
                    fill: #eb5757;
                }
            }
        }
        &-right {
            .icon {
                margin-left: 10px;
            }
            .btn {
                margin-right: 20px;
            }
        }
        &-selected {
            margin-right: 10px;
            width: 100px;
            span {
                color: #637d8b;
                font-size: 15px;
            }
            div {
                color: #637d8b;
                font-size: 15px;
                font-weight: bold;
                margin-left: 10px;
            }
        }
    }
    &__wrap {
        justify-content: space-between;
    }
    &__folders {
        min-height: 60vh;
        max-height: 60vh;
        position: relative;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            width: 0;
        }
    }
    &__drop {
        height: 5px;
    }
    &-top {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        .MuiFormControl-root {
            width: 25%;
            margin-right: 25px;
            .icon {
                display: flex;
                margin-right: 5px;
            }
        }
    }
    &__items {
        &-interface {
            padding-right: 10px;
        }
        &-edit {
            .icon {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
        .list-interface {
            li {
                cursor: pointer;
                font-size: 13px !important;
            }
        }
        .right {
            > div {
                display: flex;
            }
        }
        .left {
            display: flex;
            align-items: center;
            width: 100%;
            span {
                font-size: 12px;
            }

            input {
                color: #445259;
                font-size: 15px;
            }
        }
        .files {
            margin-left: 50px;
            &-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 25px;
                cursor: pointer;
                position: relative;
                height: 48px;
                .icon {
                    margin-left: 20px;
                }
                &:hover {
                    background: #f7f9fa;
                    input {
                        background: #f7f9fa;
                    }
                }
            }
        }
        &-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            cursor: pointer;
            position: relative;
            &:hover {
                background: #f7f9fa;
            }
            .move {
                position: absolute;
                background-color: #f7f9fa;
                display: none;
                left: -45px;
            }
            &:hover {
                background: #f7f9fa;
                .move {
                    display: block;
                }
                input {
                    background: #f7f9fa;
                }
            }
        }
        &-info {
            display: flex;
            align-items: center;
            width: 100%;

            > div {
                width: 100%;
            }
            .input {
                max-width: 100%;
                width: 100%;
            }
            &.child {
                margin-left: 15px;
            }
            .progress {
                max-width: 100%;
                margin-top: 10px;
            }
        }
    }
    &__actions {
        display: flex;
        &-item {
            display: flex;
            cursor: pointer;
            align-items: center;
            margin-left: 30px;
            border: 1px solid #edeff0;
            border-radius: 12px;
            padding: 7px 30px;
            .upload-file {
                padding: 25px 0;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            svg {
                margin-left: 10px;
            }
            &:first-child {
                margin-left: 0;
            }
            label {
                display: flex;
                cursor: pointer;
                align-items: center;
            }
            span {
                font-size: 13px;
                color: $content-medium;
            }
            .icon {
                margin-top: 3px;
            }
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.dropZone.active,
.trashDropZone.active {
    background: #00a2ff;
    transition: 100ms all;
}

.draggable-target {
    background: #e2e2e2;
    .files {
        &-wrap {
            &:hover {
                background: #e2e2e2;
            }
        }
    }
}

.remove-file {
    h6 {
        margin-bottom: 32px;
    }
    max-width: 90%;
    margin: 0 auto;
    span {
        color: #6c6c6c;
        font-size: 15px;
        display: block;
        margin-bottom: 25px;
        text-align: center;
        margin-left: auto;
        line-height: 150%;
        max-width: 90%;
        margin-right: auto;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            &:first-child {
                margin-right: 30px;
            }
        }
    }
}
.select-folder {
    h5 {
        margin-bottom: 32px;
        text-align: center;
    }
    &__item {
        height: 102px;
        padding: 0 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        &.active {
            background: #cbddf9;
        }
        div {
            font-size: 19px;
        }
        .icon {
            margin-right: 12px;
        }
        span {
            color: #6c6c6c;
            font-size: 15px;
            display: block;
            margin-bottom: 10px;
        }
    }
}

.detail-file {
    h5 {
        margin-bottom: 44px;
    }
    ul {
        li {
            div {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 12px;
            }

            margin-bottom: 40px;
        }
    }
}

.checkbox__field {
    border: 1px solid #edeff0 !important;
}

.knowledge-base__items-name {
    margin-left: 20px;
    font-size: 14px;
    color: $light-black;
}

.knowledge-base-main-item {
    position: relative;
    .list {
        position: absolute;
        right: 0;
        z-index: 999;
        top: 70%;
        background: #ffffff;
        box-shadow: 0px 4px 24px rgba(56, 166, 226, 0.08);
        border-radius: 12px;
        @media (max-width: 768px) {
            &.moveToTop {
                top: -195px;
            }
        }
        &-line {
            width: 100%;
            height: 1.5px;
            background: #edeff0;
            margin-bottom: 15px;
        }
        &-wrap {
            padding: 20px;

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 20px 10px 20px;
                span {
                    font-size: 14px;
                    color: #eb5757;
                }
                .icon {
                    svg {
                        path {
                            fill: #eb5757;
                        }
                    }
                }
            }
        }
        li {
            font-size: 14px;
            color: $light-black;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .knowledge-base__drop {
        position: absolute;
        height: 30px;
        width: 100%;
        &.top {
            top: -30px;
            bottom: 50px;
        }
    }
}

.sendFiles {
    height: 100%;
    h4 {
        margin-bottom: 12px;
    }
    &-desc {
        margin-bottom: 24px;
        strong {
            line-height: 19px;
        }
    }
    .search {
        margin-bottom: 24px;
    }
    &-footer {
        margin-top: 40px;
        .btn {
            &:first-child {
                margin-right: 24px;
            }
        }
    }
    .sendFile-course__item {
        height: 60px;
    }
    &-courses {
        @media (max-width: 768px) {
            max-height: 640px !important;
        }
    }
    &-course {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        img {
            width: 100px;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
        }
        &__img {
            width: 100px;
            margin-right: 20px;
            height: 50px;
        }
    }
}

@media (max-width: 768px) {
    .knowledge-base {
        padding: 0 16px;
        &.selectedFile {
            padding: 80px 16px;
        }
        .modal__content {
            padding: 25px 15px;
        }
        &__wrap {
            flex-direction: column;
            align-items: flex-start !important;
            h3 {
                font-size: 24px;
                width: 100%;
                text-align: left;
            }
            h1 {
                margin-bottom: 20px;
            }
        }
        .search {
            display: none;
        }
        .search-course {
            display: flex;
        }
        &-top {
            margin-top: 20px;
            .customSelect {
                display: inline-block;
                &.showType {
                    margin-left: 0;
                }
            }
        }
        &__folders {
            margin-top: 25px;
        }
        &__footer {
            position: fixed;
            top: 56px;
            padding: 0 20px;
            width: 100%;
            left: 0;
            box-shadow: 0px 0.3px 0px #ecebf5, 0px 0px 16px rgba(0, 0, 0, 0.04);
            border: 0;
            height: 60px;
        }
        &__actions {
            width: 100%;
            &-item {
                border: 0;
                padding: 0;
                span {
                    font-size: 14px;
                    color: $light-emotion-link;
                    font-weight: 500;
                }
                svg {
                    width: 17px;
                    height: 17px;
                    path {
                        fill: $light-emotion-link;
                    }
                }
            }
        }
        &__items {
            &:hover {
                background: #e2e2e2;
            }

            &-name {
                word-break: break-word;
            }
        }
    }
    .sendFiles {
        &-courses {
            max-height: 100%;
        }
        &-course__img {
            img {
                width: 85px;
            }
        }
        h4 {
            font-size: 21px;
            font-weight: bold;
            margin-bottom: 35px;
        }
        &-desc {
            div {
                font-size: 15px;
                color: #445259;
                line-height: 140%;
            }
        }
        &-footer {
            margin-top: 10px;
            position: sticky;
            bottom: 10px;
        }
    }
}

.knowledge-base__folders-wrap {
    position: relative;
    height: 60vh;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropzone-placeholder {
    border: 1px solid #edeff0;
    border-radius: 16px;
    width: 105%;
    height: 105%;
    position: absolute;
    padding: 20px 15px;
    z-index: 99999;
    background: rgba(42, 122, 242, 0.02);
    .icon {
        margin-right: 10px;
    }
    &__title {
        color: #2a7af2;
        font-weight: 500;
    }
    &__border {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border: 1px dashed #2a7af2;
        background: rgba(42, 122, 242, 0.02);
    }
}

.success-modal {
    text-align: center;
    .icon {
        margin: 70px 0;
    }
    &__title {
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 20px;
    }
    &__description {
        line-height: 20px;
        font-size: 15px;
        word-break: break-word;
        max-width: 90%;
        margin: 0 auto;
        color: $content-medium;
    }
}
