.settings {
  display: grid;
  grid-template-columns: 245px auto;
  grid-gap: 48px;
  overflow: auto;

  &__content {
    max-width: 636px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    align-content: start;
  }
}