.header-history {
  & > div > svg {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 768px){
  .header-history {
    display: none !important;
  }
}