@import "src/styles/variables";

.profile {
  display: grid;
  grid-template-columns: 245px auto;
  grid-gap: 48px;
  overflow: auto;

  &__content {
    max-width: 636px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    align-content: start;
  }
}

@media screen and (max-width: 768px) {
  .profile {
    position: fixed;
    top: 56px;
    height: calc(100% - 56px);
    background-color: $white;
    z-index: 100;

    &__content {
      max-width: 100%;

      & > form {
        border: none;
        border-radius: initial;
        max-width: inherit;
        padding: 0;
      }
    }
  }
}