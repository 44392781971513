.published__title {
    padding-bottom: 26px;
    text-align: center;
}

.published-materials {
    padding-bottom: 19px;
    &__title {
        font-weight: 600;
        margin-bottom: 7px;
    }
    &__items {
        max-height: 406px;
        overflow-y: auto;
        padding-left: 25px;
        li {
            list-style: disc;
        }
    }
    &__item {
        padding: 5px 0;
    }
}

.published-icon {
    margin-bottom: 80px;
}
.published-modal {
    .modal__content {
        max-width: 60vw;
        @media all and (max-width: 900px) {
            max-width: 80vw;
        }
    }
}
