@import "src/styles/variables";

.header-company {
  height: 40px;
  margin-left: 24px;
  min-width: 100px;

  & > .dropdownHeader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__img {
    width: 24px;
    height: 24px
  }

  &__title {
    margin-left: 8px;
    color: $content-major;
    font-weight: 500;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    cursor: pointer;
  }

  &__body {
    width: 230px;
    padding-top: 14px;
    padding-bottom: 12px;
    left: 0;
  }

  &__name {
    font-weight: 600;
    line-height: 120%;
    color: $content-low;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__spaces {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;
  }

  &__space {
    padding: 13.5px 20px;
    display: flex;
    align-items: center;
    margin-top: 4px;

    &:hover {
      background-color: $bg-secondary;
      cursor: pointer;

      .space {
        &-img {
        }

        &-name {
          color: $content-major;
        }
      }
    }

    &.picked {
      background-color: $white;
      cursor: default;

      .space {
        &-img {
          filter: grayscale(0);
        }

        &-name {
          color: #38A6E2;
        }
      }
    }

    &.active {
      .space {
        &-img {
          filter: grayscale(0);
        }

        &-name {
          color: $primary;
        }
      }
    }

    .space {
      &-img {
        width: 25px;
        height: 25px;
      }

      &-name {
        margin-left: 12px;
        color: $content-low;
      }
    }
  }

  &__divider {
    width: 100%;
    padding: 0 -20px;
  }

  &__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
  }
}