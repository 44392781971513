.default-multi-value {
  &__text {
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 475px){
      width: 250px;
    }
    @media screen and (max-width: 375px){
      width: 200px;
    }
  }
}