.auth-logo-bg {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 412px;
  max-height: 412px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  pointer-events: none;

  @media screen and (max-width: 1023px){
    max-width: 312px;
    max-height: 312px;
  }

  @media screen and (max-width: 524px){
    max-width: 212px;
    max-height: 212px;
  }
}