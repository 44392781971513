@import "../../../../styles/variables";

.group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  & > .icon {
    cursor: pointer;
  }

  &:hover, &.selected {
    background-color: $bg-secondary;

    .group-item__name {
      color: $content-major;
      font-weight: 500;
    }
  }

  &__avatar {
    cursor: pointer;
    width: 36px !important;
    height: 36px !important;
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: center;

    & > * {
      &:first-child {
        width: 60%;
        margin-right: 16px;
      }

      &:last-child {
        max-width: 228px;
        width: 40%;
      }
    }
  }

  &__qty {
    max-width: 120px;
    width: 100%;

    & > p:first-child {
      font-weight: 600 !important;
      color: $content-low;
    }
    & > p:last-child {
      font-weight: 500 !important;
    }
  }

  &__dropdown {
    margin-left: auto;
    cursor: pointer;

    .dropdownBody {
      right: 0;
      padding: 8px 0;
      width: 219px;
    }
  }

  &__action {
    padding: 9px 16px;

    &.border {
      border-bottom: 1px solid $bg-border;
    }

    &:hover, &:active, &:focus {
      background-color: $bg-secondary;
      cursor: pointer;

      & > p {
        color: $content-major;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .group-item {
    & > .checkbox, &__qty, &__dropdown {
      display: none;
    }
  }
}