@import "src/styles/variables";

.header-settings-space {
  display: none;
}

@media screen and (max-width: 768px) {
  .header-settings-space {
    display: flex;
    align-items: center;

    &__title {
      color: $content-major-light;
      margin-right: 2px;
    }
  }
}