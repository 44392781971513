@import "src/styles/variables";

.settings-colors-modal-wrapper {
  & > div {
    width: 712px;
  }

  &__content {
    margin-top: 32px;
  }

  &__sample {
    margin-bottom: 24px;

    .sample {
      &__text {
        color: $content-low;
        font-weight: 600;
      }

      &__field {
        max-width: 100%;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    & > * {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .settings-colors-modal-wrapper {
    &__content, &__title {
      padding: 0 16px;
    }
  }
}