@import "src/styles/variables";

.main-history {
  &__img {
    padding: 2px;
    border-width: 2px;
    border-style: solid;
    border-color: $bg-border;
    border-radius: 12px;
    width: 136px;
    height: 180px;
    object-fit: cover;

    &.isPreview {
      border-color: $primary;
    }

    @media screen and (max-width: 425px) {
      width: 96px;
      height: 128px;
    }
  }
}