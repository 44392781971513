@import "../../../../../styles/variables";

.student-courses-swiper {
  margin-bottom: 36px;
  h5 {
    word-break: break-word;
  }
  .swiper-wrapper {
    padding: 16px;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .swiper-slide {
    padding: 16px;
    &:hover {
      background: #fff;
      box-shadow: 0 4px 24px rgba(56, 166, 226, 0.08);
      border-radius: 16px;
    }
  }
  &__item {
    position: relative;
    .item {
      &__img {
        cursor: pointer;
        max-width: 343px;
        width: 100%;
        height: 193px;
        border-radius: 16px;
        object-fit: cover;
      }

      &__fav {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        cursor: pointer;

        & > div > svg {
          width: 24px;
          height: 24px;
        }

        &.isWhite {
          & > div > svg > path {
            fill: $white;
          }
        }
      }

      &__info {
        color: $content-low;
        margin-top: 12px;
      }

      &__name, &__progress {
        margin-top: 8px;
      }

      &__percentage {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__percent {
        font-size: 13px;
      }

      &__btn {
        margin-top: 12px;
      }
    }
  }
}