@import "src/styles/variables";

.settings-menu {
  margin-top: 92px;
  display: flex;

  &__list {
    width: 100%;
  }

  &__item {
    width: 245px;

    &.divider {
      border-bottom: 1px solid $bg-border;
    }
  }
}

@media screen and (max-width: 1024px) {
  .settings-menu {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .settings-menu {
    display: none;
  }
}