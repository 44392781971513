@import "../../../../styles/variables";

.auth-link {
  margin-top: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    color: $content-low;
  }

  &__link {
    margin-left: 4px;
  }
}