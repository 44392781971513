.create-course-settings-bottom-sheet-menu {
  &__title {
    text-align: center;
    padding: 12px 0;
  }

  &__link {
    padding: 15px 28px;
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 12px;
  }
}