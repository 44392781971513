.warn-modal {
  .modal__content {
    width: 511px;
  }

  &__title {
    text-align: center;
  }

  &__desc {
    margin-top: 24px;
    text-align: center;
  }

  &__groupButtons {
    margin-top: 24px;
    display: flex;
    justify-content: center;

    & > * {
      &:first-child {
        margin-right: 24px;
      }
    }
  }
}