@import "styles/variables";
.notifications {
  .modal__content {
    height: 100vh;
    overflow-y: scroll;
    padding: 32px 35px;
  }
  &__title {
    margin-bottom: 30px;
  }
  &__item {
    border-bottom: 1px solid $bg-border;
    margin-bottom: 24px;
    padding-bottom: 12px;
    cursor: pointer;
    &.isRead {
      position: relative;
      &:after {
        content: "";
        width: 6px;
        right: 0;
        top: 0;
        height: 6px;
        position: absolute;
        background-color: #F55C45;
        border-radius: 50%;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &-wrapper {
      display: flex;
    }
    &-time {
      color: $content-low;
      display: block;
      margin-bottom: 7px;
      font-weight: 600;
      font-size: 12px;
    }
    &-title {
      color: $content-major;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 7px;
    }
    span {
      color: $content-medium;
      line-height: 160%;

    }
    &-img {
      margin-right: 12px;
      div {
        background: gray;
      }
      img, div {
        object-fit: cover;

        width: 50px;
        height: 50px;
        border-radius: 12px;

      }
    }
  }
  @media (min-width: 992px) {
    .dialogModal>.modal__content {
      width: 450px;
    }
  }
}


.notifyDetail {
  &__text {
    margin: 20px 0;
  }
  &__img {
    margin-bottom: 16px;
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 24px;
    }
  }
  &__back {
    span {
      color: $content-link;
      font-weight: 500;
    }
    svg {
      margin-right: 5px;
      path {
        fill: $content-link
      }
    }
  }
}