.ce-popover {
    min-width: 282px;

    &__item-icon {
        width: 40px;
        height: 40px;
    }
    @media screen and (max-width: 650px) {
        &__items {
            padding-bottom: 55px;
        }
    }
}
.cdx-block {
    padding: 0;
    margin: 0.4em 0;
}
.ce-popover__item-icon svg {
    width: 24px !important;
    height: 24px !important;
}
.ce-toolbar__actions--opened {
    right: unset !important;
    left: 0 !important;
}
.ce-toolbar__content {
    max-width: 854px;
}
.cdx-mark {
    background: rgba(245, 235, 111, 0.29);
    padding: 3px 0;
}
.codex-editor {
    max-width: 854px;
    margin: 0 auto;
    z-index: 10;
    .ce-block {
        padding: 0 60px;
        &__content {
            cursor: text;
        }

        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &--narrow {
        .ce-block--focused {
            margin-right: 0 !important;
        }
    }
}
.codex-editor__redactor {
    padding-bottom: 50px !important;
}
// quote customization
.cdx-input.cdx-quote__text {
    position: relative;
    color: #445259;
    min-height: 50px;
    line-height: 150%;
    word-break: break-all;
    font-weight: 400;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 18px;
        height: 14px;
        background: url('../../../assets/img/png/quote.png');
    }
}
.ce-code .ce-code__textarea {
    border-radius: 12px;
    padding: 16px 24px;
}

.cdx-input.cdx-quote__caption,
.cdx-input.cdx-quote__text {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
    padding-left: 33px;
}
.cdx-input.cdx-quote__caption {
    color: #637d8b;
}
//end of quote customization

.ce-inline-toolbar__actions {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ce-delimiter {
    &:before {
        display: inline-block;
        content: '' !important;
        font-size: 30px;
        line-height: 65px;
        height: 12px;
        letter-spacing: 0.2em;
        border-bottom: 2px solid rgba(55, 53, 47, 0.16);
        width: 100%;
    }
}

.cdx-attaches--with-video,
.cdx-attaches--with-audio {
    .cdx-attaches__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        outline: none;
        max-width: 90%;
        padding-top: 15px;
        font-weight: 500;
        line-height: 1em;
        &:empty::before {
            content: attr(data-placeholder);
            color: #7b7e89;
        }
    }
}

.cdx-attaches--with-video .cdx-attaches__title {
    font-size: 17px;
}

.cdx-attaches--with-audio .cdx-attaches__title {
    font-size: 15px;
}

// toggle list start
.ce-toggle-list {
    display: flex;
    &__content-header {
        outline: none;
        line-height: 18px;
        padding: 3px 2px;
        &:empty::after {
            color: gray;
            content: attr(data-placeholder) !important;
            cursor: text;
        }
    }
    &__content {
        outline: none;
        line-height: 25px;
        padding: 3px 2px;
        display: none;
        &:empty::after {
            color: gray;
            content: attr(data-placeholder) !important;
            cursor: text;
        }
    }
    &__content-wrapper {
        width: calc(100% - 18px);
        padding-left: 10px;
    }
    &__arrow {
        margin-top: 5px;
        width: 15px;
        height: 15px;
        background-image: url('./../../../assets/img/png/down.png');
        background-size: cover;
        transition: transform ease-in 100ms;
        &[data-active='true'] {
            transform: rotate(90deg);
            & + .ce-toggle-list__content-wrapper .ce-toggle-list__content {
                display: block;
            }
        }
    }
}

//table
.tc-row,
.tc-cell,
.tc-add-column,
.tc-table {
    border-width: 3px !important;
}
.tc-wrap {
    padding-left: 20px;
}
.tc-toolbox--row {
    left: calc(-1 * var(--popover-margin) + 20px) !important;
}

//image
.image-tool {
    &__caption {
        line-height: 25px;
    }
}
//course
.course {
    @media (min-width: 651px) {
        .ce-toolbox--opened-top {
            top: 35px;
        }
    }
}

.image-tool__caption:empty {
    display: none;
}
.cdx-attaches {
    &__title:empty {
        display: none;
    }
    &--with-file {
        cursor: pointer;
        .cdx-attaches__title {
            cursor: text;
            line-height: 1.3em !important;
        }
    }
}

.draft {
    .image-tool__caption:empty {
        display: block;
    }
    .cdx-attaches__title:empty {
        display: block;
    }
}
