.format-option-label-course {
  display: flex;
  align-items: center;

  &__img {
    width: 85px;
    height: 48px;
    border-radius: 8px;
    margin-right: 16px;
    object-fit: cover;
  }

  &__name {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__invite {
    margin-top: 2px;
  }
}