@import "src/styles/variables";

.group-detail-info {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  &__img {
    width: 96px !important;
    min-width: 96px !important;
    height: 96px !important;
    min-height: 96px !important;
  }

  &__content {
    width: 100%;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    margin-bottom: 7px;

    & > h4 {
      color: $content-medium;
      line-height: 140%;
    }
  }

  &__more {
    margin-left: 12px;
    cursor: pointer;
  }

  &__points {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      width: 106px;
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__qty {
    margin-top: 4px;
    font-weight: 500;
  }

  &__qty-name {
    color: $content-low !important;
    font-weight: 600 !important;
  }

  &__dropdown {
    .dropdown {
      &__body {
        left: 0;
        width: 219px;
        box-shadow: 0 4px 24px rgba(56, 166, 226, 0.08);
      }

      &__list {
        padding: 8px 0;
      }

      &__item {
        padding: 9px 16px;

        &:hover {
          cursor: pointer;
          background-color: $bg-secondary;
        }

        &.border {
          border-bottom: 1px solid $bg-border;
        }
      }
    }
  }
}

@media screen and (max-width: 575px){
  .group-detail-info {
    &__img {
      width: 48px !important;
      min-width: 48px !important;
      height: 48px !important;
      min-height: 48px !important;
      align-self: flex-start;
    }

    &__content {
      flex-wrap: wrap;
    }

    &__about {
      width: 100%;
    }

    &__name {
      justify-content: space-between;
    }

    &__points {
      margin-top: 8px;
    }

    &__dropdown {
      .dropdown {
        &__body {
          left: unset;
          right: 0;
        }
      }
    }
  }
}