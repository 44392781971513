.students-wrapper {
  height: 100%;
  position: relative;
}

.students {
  max-width: 734px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &.shift {
    margin-bottom: 48px;
  }

  .users-table-list {
    & > * {
      &:nth-last-child(-n+2) {
        .dropdownBody {
          bottom: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .students {
    max-width: 100%;
  }
}