@import "styles/variables";
.analytics-groups {
  .users-table-filter__all {
    margin-right: 30px;
  }
  .sort-list {
    margin-bottom: 30px;
  }
  &-modal {
    &__title {
      font-size: 28px;
      font-weight: bold;
      color: $content-major;
      margin-bottom: 24px;
    }
    &__course-total {
      margin: 20px 0;
      div {
        color: $content-low;
      }
      span {
        color: $content-medium
      }
    }
    &__courses {
      &-item {
        img {
          max-width: 85px;
          height: 48px;
          object-fit: cover;
          border-radius: 7px;
          margin-right: 16px;
        }
        div {
          word-break: break-all;
          font-size: 15px;
          font-weight: 600;
          color: $content-medium;
        }
      }
    }
    &__back {
      margin-top: 20px;
      .btn {
        width: 100%;
      }
    }
  }
  .access-course-modal {
  }
  .modal {
    &__content {
      @media (min-width: 990px) {
        width: 634px;
      }
    }
  }
}

@media (max-width: 768px) {
  .groups-student-mobile {
    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 16px;
    }
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;
      span {
        color: $content-low;
        font-weight: 600;
        font-size: 10px;
      }
      .title {
        white-space: nowrap;
      }
      .statistics-item {
        margin-left: 20px;
      }
      div {
        word-break: break-all;
        font-size: 13px;
        color: $content-medium;
      }
    }
    &__progress {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        margin-right: 5px;
        font-size: 10px;
        color: $content-low;
        font-weight: 600;
      }
      div {
        font-weight: 600;
        font-size: 10px;
        color: $content-medium;
      }
      .progress {
        margin-left: 16px;
      }
    }
  }
}