@import "styles/variables";

.analytics-table {
  &__name {
    display: inline-block;
  }
  &.isClickable {
    tr {
      cursor: pointer;
      &:hover {
        background: #F7F9FA;
      }
    }
  }
  th {
    width: 100px;
    color: $content-low;
    font-size: 12px;
  }
  td {
    span {
      color: $content-medium
    }
  }
  &.withoutHead, &.withoutBorder {
    td {
      border: 0;
    }
  }
  img {
    width: 85px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
    &.isStudent {
      width: 36px;
      height: 36px;
      object-fit: cover;
      max-width: 100%;
      border-radius: 50%;
    }
  }
}


.detail-course-student-progress {
  width: 280px;
  .completed {
    white-space: nowrap;
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .analytics-groups {
    .analytics-table-mobile__headings {
      grid-template-columns: repeat(3, 33%);
    }
  }
  .detail-course-student-progress {
    flex-direction: row-reverse;
    width: 100%;
    .completed {
      margin-left: 0;
      font-size: 10px;
      margin-right: 16px;
    }

  }

  .p-20-x {
    padding: 0 20px;
  }
  .analytics-table-mobile {
    &__headings {
      display: grid;
      grid-template-columns: repeat(5, 20%);
      div {
        span {
          font-size: 10px;
          color: $content-low;
          display: block;
          margin: 10px 0;
          white-space: nowrap;
          div {
            font-size: 13px;
          }
        }
      }
    }
    &.testMobileTable {
      .analytics-table-mobile__headings {
        display: flex;
        justify-content: space-between;
      }
    }
    &__item {
      margin-bottom: 30px;
      &-name {
        img {
          width: 30%;
          border-radius: 8px;
          object-fit: cover;
          height: 48px;
        }
        div {
          font-size: 15px;
          color: $content-major;
          font-weight: 600;
          margin-left: 16px;
        }
      }
      &-avatar {
        color: $white !important;
        font-size: 14px !important;
        font-weight: 400 !important;
      }
      &-average {
        span {
          color: $content-low;
          font-size: 10px;
        }
        div {
          font-size: 13px;
          margin-top: 10px;
        }
      }
    }
    &.isStudent {
      img {
        width: 36px;
        height: 36px;
        border-radius: 50px;
      }
    }
    &.students-detail {
      .progress {
        span {
          display: none;
        }
      }
      .analytics-table-mobile__headings {
        margin-top: 15px;
        display: block;
        span {
          display: none;
        }
      }
      .analytics-table-mobile__item-name {
        div {
          div {
            div {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}