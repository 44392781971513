.upload-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  &__thumbnail {
    position: absolute;
    width: 128px !important;
    height: 128px !important;
    font-size: 36px !important;
    z-index: 1;
  }

  &__outer {
    width: 128px;
    height: 128px;
    background-color: white;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }

  &__inner {
    width: 128px;
    height: 40px;
    background: rgba(0, 0, 0, 0.32);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
  }

  &__upload {
    display: none;
  }
}