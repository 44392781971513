//Inter
@font-face {
  font-family: Inter sans-serif;
  src: url("../assets/fonts/Inter/Inter-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Inter sans-serif;
  src: url("../assets/fonts/Inter/Inter-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Inter sans-serif;
  src: url("../assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Inter sans-serif;
  src: url("../assets/fonts/Inter/Inter-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Inter sans-serif;
  src: url("../assets/fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

//Roboto
@font-face {
  font-family: Roboto sans-serif;
  src: url("../assets/fonts/Roboto/Roboto-Thin.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Roboto sans-serif;
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Roboto sans-serif;
  src: url("../assets/fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Roboto sans-serif;
  src: url("../assets/fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

//Lora
@font-face {
  font-family: Lora serif;
  src: url("../assets/fonts/Lora/Lora-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Lora serif;
  src: url("../assets/fonts/Lora/Lora-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Lora serif;
  src: url("../assets/fonts/Lora/Lora-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Lora serif;
  src: url("../assets/fonts/Lora/Lora-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

//Lyon
@font-face {
  font-family: Lyon serif;
  src: url("../assets/fonts/Lyon/Lyons Serif.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Lyon serif;
  src: url("../assets/fonts/Lyon/Lyons Serif Bold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Lyon serif;
  src: url("../assets/fonts/Lyon/Lyons Serif Black.ttf");
  font-weight: 700;
  font-display: swap;
}

//RobotoMono
@font-face {
  font-family: RobotoMono monospace;
  src: url("../assets/fonts/RobotoMono/RobotoMono-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: RobotoMono monospace;
  src: url("../assets/fonts/RobotoMono/RobotoMono-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: RobotoMono monospace;
  src: url("../assets/fonts/RobotoMono/RobotoMono-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: RobotoMono monospace;
  src: url("../assets/fonts/RobotoMono/RobotoMono-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: RobotoMono monospace;
  src: url("../assets/fonts/RobotoMono/RobotoMono-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: RobotoMono monospace;
  src: url("../assets/fonts/RobotoMono/RobotoMono-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: RobotoMono monospace;
  src: url("../assets/fonts/RobotoMono/RobotoMono-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

//IBM
@font-face {
  font-family: IBM monospace;
  src: url("../assets/fonts/IBM/IBMPlexMono-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: IBM monospace;
  src: url("../assets/fonts/IBM/IBMPlexMono-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: IBM monospace;
  src: url("../assets/fonts/IBM/IBMPlexMono-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: IBM monospace;
  src: url("../assets/fonts/IBM/IBMPlexMono-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: IBM monospace;
  src: url("../assets/fonts/IBM/IBMPlexMono-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: IBM monospace;
  src: url("../assets/fonts/IBM/IBMPlexMono-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: IBM monospace;
  src: url("../assets/fonts/IBM/IBMPlexMono-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

//EY
@font-face {
  font-family: EY sans-serif;
  src: url("../assets/fonts/EY/EYInterstate-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: EY sans-serif;
  src: url("../assets/fonts/EY/EYInterstate-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: EY sans-serif;
  src: url("../assets/fonts/EY/EYInterstate-LightBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: EY sans-serif;
  src: url("../assets/fonts/EY/EYInterstate-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}