@import "styles/variables";

.student-modal-item {
  padding: 8px;
  display: flex;
  align-items: center;

  &:hover, &.selected {
    background-color: $bg-secondary;
  }

  &__img {
    margin-right: 16px;
  }

  &__name {
    font-weight: 600;
  }

  &__invite {
    color: $content-low;
  }

  &__checkbox {
    margin-left: auto;
  }
}