@import 'styles/variables';

.course-module {
    position: relative;
    padding-left: 30px;
    &__title {
        padding: 0;
        font-size: 17px;
        border: none;
        font-weight: 600;
        width: 100%;
        resize: none;
        &::placeholder {
            color: $content-gray;
        }
        &--disabled {
            color: $content-low !important;
        }
        &:disabled {
            background: transparent;
            color: #000;
        }
    }
    &__description {
        width: 100%;
        line-height: 22px;
        max-width: 100%;
        border: none;
        font-size: 13px;
        resize: none;
        &::placeholder {
            color: $content-gray;
        }
    }
    &__move {
        position: absolute;
        left: -15px;
        top: -6px;
        display: none;
    }
    &:hover .course-module__move {
        display: block;
    }
    &__read-only:hover .course-module__move {
        display: none;
    }
    &__chip {
        background-color: $bg-secondary !important;
        & > span {
            color: $content-low;
        }
    }
}

@media screen and (max-width: 768px) {
    .course-module {
        padding-left: 0;
        &__title--wrapper {
            padding-left: 37px;
        }
    }
}

@media screen and (max-width: 400px) {
    .course-module {
        &__chip > span {
            font-size: 10px;
        }
    }
}