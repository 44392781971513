@import "styles/variables";

.format-option-label-student {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__img {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }

  &:hover {
    background-color: $bg-secondary;
  }

  .item {
    &__close {
      margin-left: auto;
      cursor: pointer;
    }
  }
}