.settings-company-square-logo-modal {
  &__cropper {
    margin-top: 24px;

    & > canvas {
      border-radius: 12px;
    }
  }

  &__upload {
    display: none;
  }

  &__ranges {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__range {
    width: 50%;
    margin-right: 64px;

    &:last-child {
      margin-right: 0;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & > input {
      margin-top: 8px;
      width: 100%;
    }
  }

  &__actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .settings-company-square-logo-modal {
    &__cropper {
      display: flex;
      justify-content: center;
    }

    &__title {
      font-size: 17px !important;
      text-align: center;
    }

    &__ranges {
      justify-content: center;
    }

    &__range {
      min-width: 100px;
      max-width: 150px;
      margin-right: 32px;
    }
  }
}