@import "src/styles/variables";

.courses-item {
  &:nth-last-child(-n+3) {
    .dropdown > .dropdownBody {
      transform: translateY(-100%);
    }
  }

  &__img {
    width: inherit;
    height: 193px;
    border-radius: 16px;
    object-fit: cover;
    cursor: pointer;

    &--loaded {
      background-color: $bg-secondary;
    }
  }

  &__draft {
    position: absolute;
    top: 4px;
    right: 4px;
    background: rgba(0, 0, 0, 0.32);
    backdrop-filter: blur(24px);
    border-radius: 4px;
    padding: 5px 8px;

    & > p {
      font-weight: 600;
      color: $white;
    }
  }

  &__wrapper {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    line-height: 140%;
    word-break: break-word;
  }

  &__date {
    margin-top: 8px;
    color: $content-low;
  }
}