@import "styles/variables";
.create-test-list {
  border-radius: 12px;
  position: absolute;
  height: 235px;
  bottom: -120px;
  width: 290px;
  z-index: 99;
   ul {
     box-shadow: 0px 4px 24px rgba(56, 166, 226, 0.08);
     background-color: #fff;
   }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 5px 10px;
    &:hover {
      background: #F7F9FA;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: block;
      margin-left: 20px;
      font-weight: 500;
      color: #061822;
    }
  }
  &__title {
    color: $light-gray;

    padding: 5px 10px;
  }
}
.create-test-block {
  width: 700px;
  margin-top: 30px;
  position: relative;
  &.existTest {
    .create-test-list {
      top: -150px
    }
  }
}


@media screen and (max-width: 800px) {
  .create-test-block {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    .create-test-list {
      position: static;
    }
  }
}