.invited-modal {
  & > div {
    max-width: 594px;
    width: 100%;
  }

  button {
    margin-top: 60px;
  }

  form {
    & > div {
      margin-top: 36px;
    }
  }

  .input {
    width: 100%;
    max-width: 100%;
  }

  &__success {
    margin: 70px 0;
  }
}

@media screen and (max-width: 768px){
  .invited-modal {
    & > .modal__content {
      & > form {
        margin-top: 36px;
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: start;

        & > *:first-child {
          margin-top: 0;
        }

        & > button {
          margin-top: 0;
          position: fixed;
          bottom: 35px;
          left: 50%;
          width: calc(100% - 24px);
          transform: translateX(-50%);
        }
      }
    }
  }
}