@import 'src/styles/variables';

.sidebar-menu {
    display: flex;
    flex-direction: column;

    &__link {
        display: flex;
        align-items: center;
        padding: 13.5px 12px 13.5px 28px;
        &:hover {
            background: #fff;
            box-shadow: 0 4px 24px rgba(56, 166, 226, 0.08);
        }
        &.active {
            .sidebar-menu__icon {
                & > svg {
                    & > path {
                        fill: $primary;
                    }
                }
            }

            .sidebar-menu__text {
                color: $primary;
            }
        }
    }

    &__text {
        margin-left: 12px;
    }

    &__divider {
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .sidebar-menu {
        flex-direction: row;
        justify-content: space-around;

        &__item {
            width: 75px;
            height: 53px;
        }

        &__icon {
            & > svg {
                width: 24px;
                height: 24px;
            }
        }

        &__link {
            height: 100%;
            padding: 0;
            flex-direction: column;
            justify-content: center;
        }

        &__text {
            margin-left: 0;
            font-size: 10px !important;
        }
    }
}
