.header-helper {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px){
    display: none;
  }
}