@import "../../../../../styles/variables";

.add-groups-modal-item {
  padding: 8px;
  display: flex;
  align-items: center;

  &:hover, &.selected {
    background-color: $bg-secondary;
  }

  &__img {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }

  &__name {
    font-weight: 400;
  }

  &__checkbox {
    margin-left: auto;
  }
}