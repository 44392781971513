.loader {
  svg {
    width: 100px;
    overflow: visible;
  }
  svg circle {
    stroke: #46BBFA;
    transform-origin: 50px 50px;
    animation: spin 1.2s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}