@import "styles/variables";
.analytics-study {

  &-detail {
    .dropdown {
      margin-left: 20px;
    }
    .users-heading__tab {
      .icon {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .users-table-filter {
    padding: 0;
    margin-bottom: 37px;
  }
}

.study-details {
  .users-table-filter {
    margin-bottom: 37px;
  }
  .course-access {
    span {
      background: #F7F9FA;
      color: $content-low;
      font-size: 10px;
      padding: 5px;
    }
  }
}

.study-details-statistic {
  &__title {
    font-size: 21px;
    font-weight: bold;
    color: $content-medium;
    margin-bottom: 43px;
  }
  .statistics-item {
    margin-bottom: 20px;
  }
  .title {
    font-size: 13px;
  }
  .value {
    font-size: 15px;
    margin-top: 12px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
}


.test-statistic {
  &__title {
    font-size: 21px;
    color: $content-medium;
    font-weight: bold;
    margin-bottom: 36px;
  }
  &__desc {
    color: $content-low;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 36px;
  }
  &__tabs {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    margin-bottom: 25px;
    &-item {
      background: #F7F9FA;
      font-weight: 500;
      font-size: 13px;
      display: block;
      border-radius: 12px 12px 0px 0px;
      margin-right: 8px;
      white-space: nowrap;
      padding: 8px 12px;
      cursor: pointer;
      &.active {
        background: $primary;
        color: #fff;
      }
    }
  }
  &__answerState {
    li {
      font-size: 13px;
      color: $content-low;
      line-height: 140%;
      margin-bottom: 16px;
      .icon {
        margin-left: 16px;
      }
    }
  }
  &__info {
    margin-bottom: 20px;
    .statistics-item {
      margin-left: 16px;
      .title {
        font-size: 13px;
        white-space: nowrap;
      }
      .value {
        font-size: 15px;
        color: $content-major
      }
      &:first-child {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}


@media (max-width: 768px) {
  .study-details {
    &.test{
      .analytics-table-mobile__headings {
        grid-template-columns: 40% 30% 20% 10%;
      }
      .analytics-table-mobile__item-name {
        justify-content: flex-start;
        .statistics-item {
          margin: 0;
          .value-black {
            margin: 0;
            margin-bottom: 7px;
          }
        }
        > div {
          margin-left: 0;
        }
      }
    }
    &.course-statistics {
      @extend .test;
      .course-access {
        margin-left: 0;
        div {
          margin-bottom: 10px;
          margin-left: 0;
        }
      }
    }
  }
}
