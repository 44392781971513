@import 'styles/variables';

.course-materials {
    padding-bottom: 100px;
    max-width: 800px;
    margin: 0 auto;
    &__modules {
        .smooth-dnd-draggable-wrapper:first-child {
            .course-module {
                padding-top: 0;
            }
        }
        .smooth-dnd-draggable-wrapper:first-child > .course-material {
            padding-top: 20px;
            .course-material__move {
                top: 14px;
            }
        }
        .smooth-dnd-draggable-wrapper:last-child > .course-material {
            padding-bottom: 20px;
        }
    }
    &__only-lessons {
        .smooth-dnd-draggable-wrapper:first-child > .course-material {
            padding-top: 20px;
            .course-material__move {
                top: 14px;
            }
        }
        .smooth-dnd-draggable-wrapper:last-child > .course-material {
            padding-bottom: 20px;
        }
    }
    &__title {
        padding-left: 30px;
        font-size: 19px;
        font-weight: 600;
        color: $content-gray;
        &--active {
            color: $content-medium;
        }
    }
    &__content .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
        overflow: visible;
    }
}

@media screen and (max-width: 768px){
    .course-materials {
        padding-bottom: 0;

        &__title {
            padding-left: 0;
        }
    }
}