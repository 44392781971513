@import "src/styles/variables";

.menu-profile {
  display: flex;
  align-items: center;

  &__avatar {
    max-width: 72px !important;
    width: 72px !important;
    max-height: 72px !important;
    height: 72px !important;
  }

  &__wrapper {
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    font-size: 21px !important;
    font-weight: 700 !important;
  }

  &__link {
    margin-top: 10px;
    color: $content-low;
  }

  &__dropdown {
    margin-left: 24px;

    .dropdownBody {
      width: 230px;
      right: 0;
    }
  }
}