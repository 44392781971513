@import "../../../styles/variables";
.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  &-loader {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
    &::before {
      content: "";
      color: $white;
      height: 50px;
      width: 50px;
      background: transparent;
      border-radius: 50%;
      border: 10px solid transparent;
      border-color: $primary $primary transparent transparent;
      animation: load .6s infinite;
    }
  }
}

@keyframes load {
  100%{
    transform: rotatez(360deg);
  }
}