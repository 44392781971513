.update-password-spoiler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 24px;

  &__change {
    line-height: 160%;
  }
}