.course-info {
    padding: 28px 24px;
    &__title {
        font-size: 19px;
        line-height: 23px;
        padding-bottom: 24px;
    }
    &__progress {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 16px;
        b {
            font-weight: 500;
        }
    }
    &--desktop {
        display: none;
        @media (max-width: 900px) {
            display: block;
        }
    }
    &--mobile {
        display: block;
        @media (max-width: 900px) {
            display: none;
        }
    }
}
