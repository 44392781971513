@import "../../../../../styles/variables";

.users-table-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 16px;

  &__search {
    max-width: 428px;
    width: 100%;

    &.full {
      max-width: 85%;
    }
  }

  &__select {
    border: 1px solid #EDEFF0;
    border-radius: 12px;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 32px;
    min-width: 145px;

    & > .icon {
      margin-left: 4px;
    }
  }

  &__all {
    min-width: 100px;
    display: flex;
    align-items: center;

    & > p {
      margin-left: 16px;

      & > span {
        color: $content-low;
      }
    }
  }

  &__dropdown {
    z-index: 4;

    .dropdown {
      &__body {
        right: 0;
        width: 170px;
        padding: 8px 0;
        box-shadow: 0 4px 24px rgba(56, 166, 226, 0.08);
      }

      &__item {
        padding: 9px 0;

        & > p {
          padding: 0 16px;
        }

        &.border {
          border-bottom: 1px solid $bg-border;
        }

        &:hover, &:active, &:focus {
          background-color: $bg-secondary;
          cursor: pointer;

          & > p {
            color: $content-major;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .users-table-filter {
    &__search, &__all {
      display: none !important;
    }

    &.isDetailPage {
      padding-right: 0;
      padding-left: 0;
    }

    &__dropdown {
      .dropdown {
        &__body {
          left: 0;
        }
      }
    }
  }
}