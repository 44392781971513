.menu-header {
  height: 40px;

  &__company {
    margin-left: 0;

    .dropdownBody {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}