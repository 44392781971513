@import "styles/variables";
.setting-content {
  position: relative;
  height: 100%;
  &__field {
    &-desc {
      font-size: 13px;
      color: $content-low;
      margin-top: 5px;
    }
    &-title {
      margin-top: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .consider {
        display: block;
        width: 250px;
      }
      span {
        font-size: 13px;
        line-height: 140%;
        color: $content-low;
      }
      > div {
        width: 250px;
        font-size: 16.5px;
      }
    }
    .input {
      margin-top: 23px;
    }
  }
  &__btn {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .input {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 20px;
      color: #637D8B;
      font-size: 15px;
    }
    //&.percent {
    //  &:after{
    //    content: "%";
    //  }
    //}
    //&.time {
    //  &:after {
    //    content: "Минут"
    //  }
    //}
  }
}

.input:selection:after{

  content: "%";
  position: absolute;
  right: 0;
  bottom: 20px;
  color: #637D8B;
  font-size: 15px;
}