.create-course-settings-about-course {
  max-width: 539px;
  width: 100%;

  &__title {
    margin-bottom: 48px;
  }

  &__field {
    margin-top: 28px;
  }

  &__btn {
    margin-top: 48px;
  }
}