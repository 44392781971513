@import "src/styles/variables";

.main-useful {
  margin-top: 48px;

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__select {
    min-width: 142px;
    background: $white;
    border: 1px solid $bg-border;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 12px;
  }

  &__search {
    margin: 0 12px;
    max-width: 465px;
    width: 100%;
  }

  &__list {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
  }

  &__item {
    max-width: 343px;
    width: 100%;

    .item {
      &__img {
        width: 100%;
        height: 193px;
        object-fit: cover;
        border-radius: 8px;
      }

      &__date {
        margin-top: 12px;
        font-weight: 600;
        font-size: 10px;
        line-height: 140%;
        color: $content-low;
      }

      &__title {
        margin-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .main-useful {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 475px){
  .main-useful {
    &__list {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
    }
  }
}