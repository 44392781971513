@import "src/styles/variables";

.plug {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    width: 256px;
    height: 256px;
  }

  &__title, &__desc {
    color: $content-low !important;
    text-align: center;
  }

  &__title {
    margin-top: 36px;
  }

  &__desc {
    margin-top: 16px;
  }
}

@media screen and (max-width: 768px) {
  .plug {
    &__title {
      font-size: 24px !important;
    }
  }
}