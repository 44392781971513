@import "../../../../styles/variables";

.mentor-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;

  &.current {
    pointer-events: none;
    opacity: 0.5;
  }

  &__arrow {
    display: none;
  }

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:hover, &.selected {
    background-color: $bg-secondary;

    .mentor-item__name {
      color: $content-major;
      font-weight: 500;
    }
  }

  &__circle {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 1px dashed $bg-border;
    border-radius: 36px;
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: center;

    & > * {
      &:first-child {
        width: 60%;
        margin-right: 16px;
      }

      &:last-child {
        max-width: 228px;
        width: 40%;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
  }

  &__invited {
    background: $bg-secondary;
    border-radius: 4px;
    padding: 0 4px;

    & > p {
      color: $content-low
    }
  }

  &__access {
    & > p:first-child {
      font-weight: 600 !important;
      color: $content-low;
    }
    & > p:last-child {
      font-weight: 500 !important;
    }
  }

  &__select {
    cursor: pointer;
    border: 1px solid $bg-border;
    background-color: $white;
    border-radius: 12px;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__dropdown {
    margin-left: auto;

    .dropdownBody {
      right: 0;
      padding: 8px 0;
      width: 170px;
    }
  }

  &__action {
    padding: 9px 16px;

    &.border {
      border-bottom: 1px solid $bg-border;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;

      & > p {
        color: $content-disabled;
      }
    }

    &:hover, &:active, &:focus {
      background-color: $bg-secondary;
      cursor: pointer;

      & > p {
        color: $content-major;
      }
    }
  }

  &__email {
    font-weight: 600 !important;
    color: $content-low !important;
    word-break: break-word;
  }
}

@media screen and (max-width: 768px) {
  .mentor-item {
    &__checkbox {
      display: none !important;
    }

    &__access, &__dropdown {
      display: none;
    }

    &__content {
      & > * {
        &:first-child {
          width: 100%;
        }
      }
    }

    &__block {
      flex-wrap: wrap;
    }

    &__arrow {
      display: block;
    }
  }
}