@import "styles/variables";
.test-excel {
  &__top-wrapper {
    margin-bottom: 20px;
  }
  &__title {
    font-size: 28px;
    font-weight: bold;
  }
  &__desc {
    font-size: 15px;
    color: $content-medium;
    line-height: 150%;
  }
  &__template {
    background: #F7F9FA;
    border-radius: 8px;
    padding: 10px;
    div {
      font-size: 11px;
      margin-left: 5px;
    }
  }
  &__content {

    height: 100px;
  }
  &__upload {
    height: 100%;
    border: 1px dashed #2A7AF2;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(42, 122, 242, 0.02);
    margin-top: 20px;
    &-title {
      font-size: 13px;
      font-weight: 500;
      color: #2A7AF2;
      margin-left: 12px;
    }
  }
}


.file-state {
  display: flex;
  align-items: center;
  margin-top: 40px;
  width: 100%;
  svg {
    width: 24px;
    height: 24px;
  }
  .j-c-b {
    margin-bottom: 10px;
  }
  &__progress {
    width: 100%;
    margin: 0 20px;
  }
  &__name {
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
  }
  &__size {
    color: #A8AFB2;
    font-size: 13px;
  }
  .progress {
    max-width: 100%;
  }
  .j-c-b {
    width: 100%;
  }
  .icon {
    display: block;
  }
}