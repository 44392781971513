.settings-company {
  &__title {
    margin-bottom: 48px;
  }

  &__field {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      max-width: 343px;

      & > input {
        width: 343px;
      }
    }
  }
}

@media screen and (max-width: 575px){
  .settings-company {
    &__field {
      flex-wrap: wrap;

      & > button {
        margin-top: 16px;
      }

      & > div {
        max-width: 100%;
        width: 100%;

        & > input {
          width: 100%;
        }
      }
    }
  }
}