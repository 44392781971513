.block-interface {
  background: #F7F9FA;
  border-radius: 16px 0px 0px 16px;
  position: fixed;
  top: 25%;
  right: 0;
  display: flex;
  align-items: center;
  padding: 3px 15px;
  cursor: pointer;
  &.uploadFileTest {
    top: 35%;
    height: 42px;
    .icon {
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: 800px) {
      display: none;
  }
  span {
    color: #637D8B;
  }
}