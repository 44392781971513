@import "src/styles/variables";

.header-settings-space-menu {
  display: flex;

  &__list {
    width: 100%;
  }

  &__item {
    width: 245px;

    &.divider {
      border-bottom: 1px solid $bg-border;
    }
  }

  &__link {
    padding: 15px 28px;
    display: flex;
    align-items: center;

    &.active {
      & > div > svg > path {
        fill: $primary;
      }

      & > p {
        color: $primary;
      }
    }
  }

  &__text {
    margin-left: 12px;
  }
}