@import "src/styles/variables";

.courses-heading {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__tabs {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 0;

    & > p {
      color: $content-low;
    }

    & > .icon {
      margin-right: 8px;

      & > svg > path {
        fill: $content-low;
      }
    }

    &.active {
      & > .icon > svg > path {
        fill: $content-major;
      }

      & > p {
        color: $content-major;
        font-weight: 500;
      }
    }
  }

  &__btn {
    border: 0;
  }
}

@media screen and (max-width: 475px) {
  .courses-heading {
    &__tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &__btn {
      display: none !important;
    }
  }
}
