@import 'styles/variables';

.course-files {
    padding-bottom: 30px;
    padding-left: 30px;
    max-width: 800px;
    margin: 0 auto;
    &__content {
        padding-left: 18px;
    }
    &__input {
        display: none;
    }
    &__title {
        font-size: 17px;
        font-weight: 600;
        color: $content-medium;
    }
    &__add {
        font-size: 13px !important;
        font-weight: 500 !important;
        color: $content-gray !important;
        margin-left: 20px !important;//todo: Zhaslan must check css priorities
    }
    &__label {
        display: block;
        margin-top: 18px;
        &--hidden {
            display: none;
        }
    }
}

@media screen and (max-width: 768px){
    .course-files {
        padding-left: 0;

        &__content {
            padding-left: inherit;
        }
    }
}