@import "src/styles/variables";

.sidebar {
  position: fixed;
  top: 56px;
  width: 219px;
  height: 100%;
  grid-area: sidebar;
  padding-top: 8px;
  background: $white;
  box-shadow: 3px -2px 4px $sidebar-box-shadow-large;

  &-small {
    width: 72px;

    .sidebar-menu__link {
      padding: 0;
      height: 48px;
      justify-content: center;
    }

    .sidebar-menu__text {
      display: none;
    }
  }
}

@media screen and (max-width: 768px){
  .sidebar {
    width: 100%;
    position: fixed;
    height: 56px;
    top: unset;
    bottom: 0;
    left: 0;
    padding-top: 0;
    box-shadow: 0px -0.3px 0px #ECEBF5, 0px 0px 16px $sidebar-box-shadow-small;
    backdrop-filter: blur(36px);
    z-index: 99;

    & > nav > ul {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}