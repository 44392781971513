@import "styles/variables";

.create-course-cover {
  max-width: 734px;
  margin: 0 auto;
  &__upload {
    display: none;
  }

  &__cover {
    position: relative;
  }

  &__img {
    width: 100%;
    height: 412px;
    object-fit: cover;
    border-radius: 12px;
  }

  &__edit {
    position: absolute;
    bottom: 2px;
    left: 16px;
    border: none;
  }

  &__btn {
    & > p {
      font-weight: 500;
    }
  }
}