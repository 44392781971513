@import "styles/variables";

.bottom-modal-wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;
  }

  &__clearText {
    color: $content-low;

    & > span {
      font-weight: 600;
    }
  }

  &__clear {
    margin-left: 16px;
  }

  &__right {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}