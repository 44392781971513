.profile-settings {
  &__content {
    margin-top: 48px;

    & > * {
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}