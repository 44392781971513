.headerUploadCourseBtn {
  font-weight: 500;
  line-height: 100%;
  white-space: nowrap;
  background: #fff;

  &:active:enabled,
  &:focus:enabled {
    background: #fff !important;
    color: #445259 !important;
    border-color: #edeff0 !important;
  }

  &:focus:enabled > .icon > svg > path,
  &:active:enabled > .icon > svg > path {
    fill: rgb(99, 125, 139) !important;
    stroke: none !important;
  }

  .icon {
    padding-left: 10px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
