@import "src/styles/variables";

.create-course-settings-menu {
  margin-top: 92px;
  display: flex;

  &__list {
    width: 100%;
  }

  &__item {
    width: 245px;

    &.divider {
      border-bottom: 1px solid $bg-border;
    }
  }

  &__link {
    padding: 15px 28px;
    display: flex;
    align-items: center;

    &.active {
      & > div > svg > path {
        fill: $primary;
      }

      & > p {
        color: $primary;
      }
    }
  }

  &__text {
    margin-left: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .create-course-settings-menu {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .create-course-settings-menu {
    display: none;
  }
}