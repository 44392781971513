@import "src/styles/variables";

.student-detail-group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:hover, &.selected {
    background-color: $bg-secondary;

    .student-detail-group-item__name {
      color: $content-major;
    }
  }

  &__img {
    cursor: pointer;
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    object-fit: cover;
  }

  &__name {
    color: $content-medium;
    width: 60%;
    margin-right: 16px;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__qty {
    max-width: 228px;
    width: 40%;
  }

  &__text {
    color: $content-low;
    font-weight: 600;
  }

  &__info {
    margin-top: 4px;
    font-weight: 500;
  }

  &__dropdown {
    margin-left: auto;
    cursor: pointer;
    z-index: unset;

    .dropdownHeader {
      z-index: 3;
    }

    .dropdownBody {
      right: 0;
      padding: 8px 0;
      width: 219px;
      z-index: 4;
    }
  }

  &__action {
    padding: 9px 16px;

    &.border {
      border-bottom: 1px solid $bg-border;
    }

    &:hover, &:active, &:focus {
      background-color: $bg-secondary;
      cursor: pointer;

      & > p {
        color: $content-major;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .student-detail-group-item {
    margin: 0 -16px;

    &__name {
      width: 100%;
    }

    &__qty, &__checkbox {
      display: none !important;
    }
  }
}