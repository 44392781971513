@import "src/styles/variables";

.settings-colors-main-background-app-sample {
  &__sidebar {
    height: 200px;
    width: 219px;
    border-right: 1px solid $bg-border;
  }

  &__content {
    display: flex;
  }

  &__section {
    width: calc(100% - 219px);
    height: 200px;
  }
}