.header-breadcrumbs {
  display: flex;
  align-items: center;
  margin-left: 12px;

  &--long {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}