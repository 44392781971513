.student-detail-groups {
  .users-table-list {
    & > * {
      &:nth-last-child(-n+2) {
        .dropdownBody {
          bottom: 100%;
        }
      }
    }
  }
}