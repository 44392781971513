@import "styles/variables";
.empty-placeholder {
  max-width: 538px;
  margin: 0 auto;
  text-align: center;
  margin-top: 90px;
  &.onlyCenter {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  &__title {
    font-size: 19px;
    font-weight: 600;
    color: $content-medium;
    margin-bottom: 16px;
  }
  &__desc {
    font-size: 15px;
    color: $content-medium;
    line-height: 150%;
  }
}