@import "src/styles/variables";

.header-profile {
  &__header {
    display: flex;
    align-items: center;
  }

  &__icon {
    cursor: pointer;
  }

  &__body {
    width: 230px;
    right: 0;
  }
}