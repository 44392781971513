@import "src/styles/variables";

.courses-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__name {
    color: $content-medium;
  }

  &__more {
    display: flex;
    align-items: center;

    & > p {
      font-weight: 500;
      color: $content-link;
    }

    & > .icon {
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 425px) {
  .courses-title {
    &__name {
      font-size: 24px !important;
    }
  }
}