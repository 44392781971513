.group-detail {
  max-width: 734px;
  width: 100%;
  margin: 12px auto 0 auto;
}

@media screen and (max-width: 768px){
  .group-detail-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .users-table-filter {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}