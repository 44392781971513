@import "../../../../styles/variables";

.student-detail-heading {
  margin: 24px 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $bg-border;

  &__tabs {
    display: flex;

    & > * {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 0;

    & > p {
      color: $content-low;
    }

    & > .icon {
      margin-right: 8px;

      & > svg > path {
        fill: $content-low;
      }
    }

    &.active {
      & > .icon > svg > path {
        fill: $content-major;
      }

      & > p {
        color: $content-major;
        font-weight: 500;
      }
    }
  }

  &__btn {
    width: 191px;
    border: 0;
  }
}

@media screen and (max-width: 768px){
  .student-detail-heading {
    &__btn {
      display: none;
    }
  }
}

@media screen and (max-width: 475px){
  .student-detail-heading {
    &__tabs {
      width: 100%;
      justify-content: space-evenly;
    }
  }
}