@import 'styles/variables';
.lesson {
    &__material-navigation {
        max-width: 772px;
        margin: 0 auto;
        padding: 0 15px 35px;
        position: sticky;
        top: 50px;
        z-index: 999;
        @media (max-width: 768px) {
            position: fixed;
            top: unset;
            left: 0;
            bottom: 55px;
            width: 100%;
            max-width: 100%;
            padding-bottom: 5px;
        }
    }
    &__title {
        font-size: 32px;
        font-weight: 700;
        border: none;
        margin-bottom: 20px;
        width: 100%;
        padding: 0 60px;
        resize: none;
        &:disabled {
            background: transparent;
            color: #000;
        }
        @media (max-width: 768px) {
            padding: 0;
        }
    }
    &__content {
        margin: 0 auto;
        max-width: 890px;
        padding: 0 15px;
    }
    .codex-editor__redactor {
        padding-bottom: 50px !important;
    }
}
.test-info-modal {
    &__desc {
        p {
            font-weight: 400;
            font-size: 15px;
            color: $content-medium;
        }
        span {
            font-weight: bold;
        }
    }
    &__btns {
        margin-top: 50px;
    }
    &__passLater {
        font-weight: 500;
        font-size: 13px;
        color: #2a7af2 !important;
    }
    @media (max-width: 768px) {
        padding: 40px 20px 0 20px;
        overflow-y: hidden;
    }
}